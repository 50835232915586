import {
  CAT_IN_A_BOX,
  CONTRACT_BUILDER,
  EP_TO_ELT,
  WIP_APIDOCS_DOCUMENTS,
  WIP_APIDOCS_FILES,
  WIP_APIDOCS_NOTIFICATIONS,
} from '@shared/services/permission/access-zones.constant';

export const DocsServiceToAccessZone = {
  security: undefined,
  analysis: CONTRACT_BUILDER,
  contract: CONTRACT_BUILDER,
  exposurelibrary: CONTRACT_BUILDER,
  currency: CONTRACT_BUILDER,
  yltlibrary: CONTRACT_BUILDER,
  ciab: CAT_IN_A_BOX,
  eltgenerator: EP_TO_ELT,
  autoimport: CONTRACT_BUILDER,
  document: WIP_APIDOCS_DOCUMENTS,
  notifications: WIP_APIDOCS_NOTIFICATIONS,
  reference: CONTRACT_BUILDER,
  files: WIP_APIDOCS_FILES,
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GlobalsModule } from '@shared/globals/globals.module';
import { AuthModule } from '@shared/services/auth';
import { DialogModule } from '@shared/services/dialog';
import { SecurityModule } from '@shared/services/security';
import { SnackbarModule } from '@shared/services/snackbar';

import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginPageComponent } from './login-page.component';
import { LoginForgotPasswordModule } from './forgot-password/login-forgot-password.module';
import { LoginChangePasswordModule } from './change-password/login-change-password.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    LoginForgotPasswordModule,
    LoginChangePasswordModule,

    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    SnackbarModule,
    DialogModule,

    GlobalsModule,
    AuthModule,
    SecurityModule,

    LoginRoutingModule,
  ],
  declarations: [LoginComponent, LoginPageComponent],
  exports: [LoginComponent, LoginPageComponent],
  providers: [],
})
export class LoginModule {}

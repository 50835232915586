<span class="snackbar-wrapper">
  <span class="type-icon">
    <mat-icon class="success">check_circle</mat-icon>
    <mat-icon class="warning">warning</mat-icon>
    <mat-icon class="info">info</mat-icon>
    <mat-icon class="error">error</mat-icon>
  </span>

  <span class="text">{{ data.text }}</span>

  <button *ngIf="data.action" mat-flat-button class="action radius radius-plain" (click)="snackbarRef.dismissWithAction()">
    {{ data.action }}
  </button>

  <button mat-icon-button (click)="snackbarRef.dismiss()">
    <mat-icon class="close">clear</mat-icon>
  </button>
</span>

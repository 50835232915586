// IMP: Refactor service and large methods: #3211
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { ENVIRONMENT } from 'environment';

import { CONTRACT_DOCUMENT, MIU_PROFILE, PUBLIC_CONTRACT } from '../permission/access-zones.constant';
import { APIService } from '../api/api.service';

const document = 'document';
const profile = 'profile';
const DocumentNotification = 'DocumentNotification';

@Injectable()
export class EmailService {
  constructor(private _apiService: APIService) {}

  public sendContractDocument(
    programName: string,
    docName: string,
    docUrl: string,
    commentary: string,
    isPublic: boolean,
    companyNames
  ): Promise<void> {
    return this._sendContractEmails(
      programName,
      docName,
      docUrl,
      document,
      companyNames,
      [],
      this._publicContractPermission(isPublic),
      [],
      commentary
    );
  }

  public sendContractDocumentByEmailAddresses(
    programName: string,
    docName: string,
    docUrl: string,
    emailAddresses: string[],
    commentary: string
  ): Promise<any> {
    return this._sendContractEmails(programName, docName, docUrl, document, [], [], [], emailAddresses, commentary);
  }

  public sendProfile(
    programName: string,
    docName: string,
    docUrl: string,
    commentary: string,
    isPublic: boolean,
    companyNames: string[]
  ): Promise<any> {
    return this._sendContractEmails(
      programName,
      docName,
      docUrl,
      profile,
      companyNames,
      [],
      this._profilePermission(isPublic),
      [],
      commentary
    );
  }

  public sendContractByEmailAddresses(
    programName: string,
    docName: string,
    docUrl: string,
    emails: string[],
    commentary: string
  ): Promise<any> {
    return this._sendContractEmails(programName, docName, docUrl, profile, [], [], [], emails, commentary);
  }

  public sendDocumentByDocTypeCode(
    docName: string,
    docTypeCode: string,
    docTypeName: string,
    docUrl: string,
    commentary: string
  ): Promise<any> {
    const getPermissionsByDocTypeCode = (type: string): Promise<any> => {
      const permissions = `${CONSTANTS.docsUrl.permissions(type)}`;
      const url = `${ENVIRONMENT.baseUrl.document}${permissions}`;
      return this._apiService
        .get(url)
        .toPromise()
        .then((res) => res);
    };

    return getPermissionsByDocTypeCode(docTypeCode).then((res) =>
      this._sendDocumentEmails(docName, [], res, [], [], docTypeName, docUrl, commentary)
    );
  }

  public sendDocumentByEmailAddresses(docName: string, emailAddresses: string[], docType: string, docUrl: string, commentary: string) {
    return this._sendDocumentEmails(docName, [], [], [], emailAddresses, docType, docUrl, commentary);
  }
  public sendGeneralByRoles(subject: string, body: string, roleNames: string[]): Promise<any> {
    return this._sendGeneralEmails(subject, body, roleNames, []);
  }
  public sendGeneralByEmailAddresses(subject: string, body: string, emailAddresses: string[]): Promise<any> {
    return this._sendGeneralEmails(subject, body, [], emailAddresses);
  }

  public getContractUrl(contractId: number, contractVersion: string): string {
    const url = `${window.location.origin}/contracts/${contractId}/${contractVersion}/profile`;
    return this._addUrlSrc(url);
  }

  public getContractDocumentUrl(contractId: number, contractVersion: string, documentId: number): string {
    const origin = window.location.origin;
    const url = `${origin}/contracts/${contractId}/${contractVersion}/${documentId}`;
    return this._addUrlSrc(url);
  }

  public getDocumentUrl(baseUrl: string, template: string): string {
    const url = `${baseUrl}/${template}`;
    return this._addUrlSrc(url);
  }

  private _addUrlSrc(url: string): string {
    return (url += '?src=email');
  }

  private _toHtml(text: string, snippet: boolean): string {
    const isTextEmpty: boolean = text && text.trim().length > 0;
    const replaced = () => {
      const replacedText: string = text.replace(/\n/g, '<br>');
      return snippet ? `<br>${replacedText}<br>` : `<font face="calibri" color="black" size="3">${replacedText}<font/>`;
    };

    return !isTextEmpty ? '' : replaced();
  }

  private _sendDocumentEmails(
    docName: string,
    organizationNames,
    permissionNames,
    inclusivePermissionNames,
    emailAddresses: string[],
    docType: string,
    docUrl: string,
    commentary: string
  ): Promise<any> {
    const postData = {
      emailAddresses,
      organizationNames,
      permissionNames,
      inclusivePermissionNames,
      templateVariables: { docName, docType, docUrl, commentary: this._toHtml(commentary, true) },
    };

    const url = `${this._baseUrl}${CONSTANTS.emailUrl.withTemplate(DocumentNotification)}`;
    return this._apiService
      .post(url, postData)
      .toPromise()
      .then((res) => res);
  }

  private _sendContractEmails(
    programName: string,
    docName: string,
    docUrl: string,
    subjectAppend: string,
    organizationNames,
    permissionNames,
    inclusivePermissionNames,
    emailAddresses: string[],
    commentary: string
  ): Promise<any> {
    // IMP: This is currently a hack to get the subject of the emails to display as requested: #3212
    // Still have to call it 'docType' as the template uses that as the merge variable
    const subjectInfo = `${programName} ${subjectAppend}`;

    const postData = {
      emailAddresses,
      organizationNames,
      permissionNames,
      inclusivePermissionNames,
      templateVariables: {
        docName,
        docType: subjectInfo,
        docUrl,
        commentary: this._toHtml(commentary, true),
      },
    };

    const url = `${this._baseUrl}${CONSTANTS.emailUrl.withTemplate(DocumentNotification)}`;
    return this._apiService
      .post(url, postData)
      .toPromise()
      .then((res) => res);
  }

  private get _baseUrl(): string {
    return ENVIRONMENT.baseUrl.cms;
  }

  private _sendGeneralEmails(subject: string, body: string, roleNames: string[], emailAddresses: string[]): Promise<any> {
    const url = `${this._baseUrl}${CONSTANTS.emailUrl.general}`;
    const postData = {
      roleNames,
      emailAddresses,
      subject,
      body: this._toHtml(body, false),
    };
    return this._apiService
      .post(url, postData)
      .toPromise()
      .then((res) => res);
  }

  private _publicContractPermission(isPublic: boolean): string[] {
    return isPublic ? [CONTRACT_DOCUMENT, PUBLIC_CONTRACT] : [];
  }

  private _profilePermission(isPublic: boolean): string[] {
    return isPublic ? [MIU_PROFILE, PUBLIC_CONTRACT] : [];
  }
}

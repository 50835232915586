import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { ENVIRONMENT } from 'environment';
import jwtDecode from 'jwt-decode';
import { identity } from 'lodash';

import { AccessTokenData, IdTokenData, Tokens, User } from './auth-sml.interface';

const AUTH_SERVICE_STORAGE_KEY = 'RMS:AUTH_SERVICE:TOKENS';
const REFRESH_STORAGE_KEY = 'RMS_authRefreshToken';

@Injectable()
export class AuthSmlService {
  constructor(private _client: HttpClient) {}

  private parseClaimArray(claim: string | string[] = ''): string[] {
    return ([] as string[]).concat(claim).filter(identity);
  }

  private clear() {
    localStorage.removeItem(AUTH_SERVICE_STORAGE_KEY);
    localStorage.removeItem(REFRESH_STORAGE_KEY);
  }

  private generateExpiresAt(expiresIn: number): number {
    return Date.now() + expiresIn * 950;
  }

  private get tokens(): Tokens {
    return JSON.parse(localStorage.getItem(AUTH_SERVICE_STORAGE_KEY) || null);
  }

  private set tokens(tokens: Tokens) {
    if (!tokens) {
      this.clear();

      return;
    }
    localStorage.setItem(REFRESH_STORAGE_KEY, tokens.refreshToken);
    localStorage.setItem(AUTH_SERVICE_STORAGE_KEY, JSON.stringify(tokens));
  }

  public get bearerAuthToken(): string {
    return `Bearer ${this.tokens?.accessToken}`;
  }

  public get user(): User {
    const tokens = this.tokens;
    const idTokenData = jwtDecode<IdTokenData>(tokens.idToken);
    const accessTokenData = jwtDecode<AccessTokenData>(tokens.accessToken);
    const roles = this.parseClaimArray(accessTokenData.roles);
    const apps = this.parseClaimArray(accessTokenData.apps);

    return {
      apps,
      createdAt: tokens.createdAt,
      email: idTokenData.email,
      familyName: idTokenData.family_name,
      givenName: idTokenData.given_name,
      roles,
      tenantId: accessTokenData.tenant_id,
      tenantName: tokens.tenantName,
      username: accessTokenData.username,
    };
  }

  public get username(): string {
    const tokens = this.tokens;
    const accessTokenData = jwtDecode<AccessTokenData>(tokens.accessToken);

    return accessTokenData.username;
  }

  public get accessToken(): string {
    return this.tokens.accessToken;
  }

  public get refreshToken(): string {
    return this.tokens.refreshToken;
  }

  public login(username: string, password: string): Promise<Tokens> {
    return this._client
      .post(`${ENVIRONMENT.baseUrl.smlV1}${CONSTANTS.securityUrl.login}`, {
        username,
        password,
        tenantName: ENVIRONMENT.tenantName,
      })
      .toPromise()
      .then(
        (response: Tokens) => {
          this.tokens = response;

          return response;
        },
        (error) => error
      );
  }

  public isAuthenticated(): boolean {
    return Boolean(this.tokens?.username);
  }

  public refreshAccessToken(): Promise<void> {
    const { refreshToken, tenantName } = this.tokens;

    return this._client
      .post(`${ENVIRONMENT.baseUrl.smlV1}${CONSTANTS.securityUrl.refresh}`, {
        refreshToken,
      })
      .toPromise()
      .then((data: Tokens) => {
        this.tokens = {
          ...data,
          createdAt: 0,
          expiresAt: this.generateExpiresAt(data.expiresIn),
          refreshToken,
          tenantName,
        };
      });
  }

  public logout(): void {
    const headers = new HttpHeaders().set('Authorization', this.bearerAuthToken);
    const options = { headers };
    this._client.post(`${ENVIRONMENT.baseUrl.smlV1}${CONSTANTS.securityUrl.logout}`, {}, options).subscribe(() => {
      this.clear();
    });
  }
}

// IMP: Refactor into utilities: #4306

export class KeyValueStore<T> {
  public key: string;
  public values: T;

  constructor(key: string, values?: T) {
    if (key) {
      this.key = key;
      this.values = values || ({} as T);
    } else {
      throw Error('key-value-store: No key provided');
    }
  }

  public updateValues(key: string, values: T) {
    this.values[key] = values;
  }

  public removeItem(key: string) {
    delete this.values[key];
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SnackbarService } from './snackbar.service';
import { SnackbarComponent } from './snackbar.component';

@NgModule({
  // eslint-disable-next-line
  imports: [CommonModule, MatSnackBarModule, MatIconModule, MatButtonModule],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent],
  providers: [SnackbarService],
})
export class SnackbarModule {}

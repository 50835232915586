import { Component, Input } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { RouteData } from '@shared/interfaces/route-data.interface';
import { SessionService } from '@shared/services';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'mit-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input()
  public breadcrumb: string;
  public routeData$: Observable<RouteData>;
  constructor(private _sessionService: SessionService, private _route: ActivatedRoute, private _router: Router) {
    this.routeData$ = this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        const route: ActivatedRoute = this._route;
        const snapshot: ActivatedRouteSnapshot = route.firstChild ? route.firstChild.snapshot : undefined;
        if (snapshot && snapshot.data) {
          return snapshot.data as RouteData;
        } else {
          return this._defaultRouteData;
        }
      })
    );
  }

  public hasSession(): boolean {
    return this._sessionService.hasSession();
  }

  private get _defaultRouteData(): RouteData {
    return {
      title: null,
      helpMenuId: undefined,
    };
  }
}

import { flatten } from 'lodash';

type RegExpStr = string;

export class SwaggerMask {
  public tags: string[];
  public paths: RegExpStr[];
  public definitions: string[];

  constructor(tags = [], paths = [], definitions = []) {
    this.tags = tags;
    this.paths = paths;
    this.definitions = definitions;
  }

  public static merge(...masks: SwaggerMask[]): SwaggerMask {
    const tags = flatten(masks.map((i) => i.tags));
    const paths = flatten(masks.map((i) => i.paths));
    const definitions = flatten(masks.map((i) => i.definitions));
    return new SwaggerMask(tags, paths, definitions);
  }
}

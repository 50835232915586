import { NgModule } from '@angular/core';
import { WebSocketModule } from '@shared/services/web-socket';

import { NotificationService } from './notification.service';

@NgModule({
  // eslint-disable-next-line
  imports: [WebSocketModule],
  exports: [],
  declarations: [],
  providers: [NotificationService],
})
export class NotificationModule {}

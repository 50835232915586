import { NgModule } from '@angular/core';

import { DocsService } from './docs.service';

@NgModule({
  // eslint-disable-next-line
  imports: [],
  declarations: [],
  exports: [],
  providers: [DocsService],
})
export class DocsModule {}

import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { ENVIRONMENT } from 'environment';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { NotificationTopic } from './notification-topic.interface';
import { NotificationMessage } from './notification-message.interface';
import { WebSocketService } from '../web-socket/web-socket.service';
import { APIService } from '../api/api.service';

const baseUrl = `${ENVIRONMENT.baseUrl.notification}`;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _notifications: Subject<NotificationMessage> = new Subject();
  private _isRunning = false;

  constructor(private _webSocketService: WebSocketService, private _apiService: APIService) {}

  public init(): void {
    if (!this._isRunning && ENVIRONMENT.isMiuEnvironment) {
      const url = `${baseUrl}${CONSTANTS.notificationsUrl.websocket}`;
      this._isRunning = false;
      this._webSocketService
        .connect(url)
        .pipe(tap(() => (this._isRunning = true)))
        .subscribe((message) => {
          this._notifications.next(message as NotificationMessage);
        });
    }
  }

  public get messages$(): Subject<NotificationMessage> {
    return this._notifications;
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  // Admin only endpoint
  public getTopics(): Observable<NotificationTopic[]> {
    const url = `${baseUrl}${CONSTANTS.notificationsUrl.managementTopics}`;
    return this._apiService.get(url).pipe(map((data: { topics: NotificationTopic[] }) => data.topics));
  }
}

<div class="password-reset content-wrapper">
  <p>Please enter your email address to reset your credentials.</p>

  <form name="forgotPasswordForm" (ngSubmit)="onResetPassword()" #forgotPasswordForm="ngForm">
    <mat-form-field>
      <input
        matInput
        type="email"
        class="ext-login-name"
        placeholder="Email"
        autofocus
        name="email"
        ignoreLimitedChars
        [(ngModel)]="state.details.email"
        required
      />
    </mat-form-field>

    <div class="links">
      <a routerLink="/login" class="link"> Back </a>

      <a href="mailto:RMS_miuinsights@moodys.com" class="link"> Contact us </a>
    </div>

    <div class="buttons">
      <button type="submit" mat-flat-button class="radius radius-primary ext-reset-password" [disabled]="forgotPasswordForm.invalid">
        Reset Credentials
      </button>
    </div>
  </form>
</div>

import { isNull, isUndefined } from 'lodash';

const definedAndNotNull = (value): boolean => !isUndefined(value) && !isNull(value);

const compareValues = (a, b): number => {
  if (definedAndNotNull(a) && definedAndNotNull(b)) {
    return a > b ? 1 : a < b ? -1 : 0;
  } else {
    return a ? 1 : b ? -1 : 0;
  }
};
export const createSortFn = (prepFn: (item: any) => number | string | boolean | Date) => (_a: any, _b: any) => {
  const a = prepFn(_a);
  const b = prepFn(_b);
  return compareValues(a, b);
};

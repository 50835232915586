<div class="header">
  <div *ngIf="routeData$ | async as data" class="header__row">
    <div class="header__left-block">
      <h2 class="header__title">
        {{ data.title }}
      </h2>

      <h5 class="header__breadcrumb">
        {{ breadcrumb }}
      </h5>
    </div>

    <div class="header__right-block">
      <div class="header__helpmenu">
        <mit-help-menu *ngIf="hasSession()" [helpMenuId]="data.helpMenuId"></mit-help-menu>
      </div>
    </div>
  </div>
</div>

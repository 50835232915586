import { NgModule } from '@angular/core';

import { TrackingService } from './tracking.service';

@NgModule({
  // eslint-disable-next-line
  imports: [],
  declarations: [],
  exports: [],
  providers: [TrackingService],
})
export class TrackingModule {}

<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<div mat-dialog-content>
  <h4 *ngFor="let line of data.lines">{{ line }}</h4>
</div>

<div mat-dialog-actions>
  <button mat-flat-button (click)="close()" class="radius radius-primary">OK</button>
</div>

import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SecurityService, SessionService, SnackbarService } from '@shared/services';

const Forbidden = 403;
const redirectDelay = 3000;

@Component({
  templateUrl: 'login-change-password.component.html',
  styleUrls: ['login-change-password.component.scss'],
  providers: [],
})
export class LoginChangePasswordComponent implements OnInit {
  public state: LoginChangePasswordState = {
    details: {
      loginName: undefined,
      currentPassword: '',
      newPassword: '',
      newConfirmPassword: '',
    },
    passwordResetToken: undefined,
    messages: {
      changeSuccess: 'Password changed. You will be redirected shortly',
      noMatch: 'The new password does not match the confirmed new password. Please try again',
      newSameAsCurrent: `The new password is the same as current password.
        Please use a new password`,
      invalidCurrentPassword: 'Current password is incorrect',
    },
  };

  constructor(
    private _securityService: SecurityService,
    private _sessionService: SessionService,
    private _snackBarService: SnackbarService,
    private _location: Location
  ) {}

  public ngOnInit(): void {
    if (this._sessionService.hasSession()) {
      this.state.details.loginName = this._sessionService.loginName;
    } else {
      this._sessionService.logoutAndRedirect();
    }
  }

  public onChangePassword(): void {
    const details = this.state.details;
    if (this._isValidPassword(details.newPassword, details.newConfirmPassword)) {
      this._updatePassword(details.currentPassword, details.newPassword);
    } else {
      this._snackBarService.error(this.state.messages.noMatch);
    }
  }

  public onClickCancel(): void {
    this._location.back();
  }

  private _updatePassword(currentPassword: string, newPassword: string): void {
    if (!this._isSameAsCurrentPassword(currentPassword, newPassword)) {
      this._securityService
        .updatePassword(currentPassword, newPassword)
        .then(() => this._showMessageAndLogin(this.state.messages.changeSuccess))
        .catch(this._catchErrors.bind(this));
    } else {
      this._snackBarService.error(this.state.messages.newSameAsCurrent);
    }
  }

  private _catchErrors(res: HttpErrorResponse) {
    if (res.status === Forbidden) {
      this._snackBarService.error(this.state.messages.invalidCurrentPassword);
    } else {
      this._snackBarService.error(res.error[0].message);
    }
  }

  private _isValidPassword(newPassword: string, newConfirmPassword: string): boolean {
    return newPassword === newConfirmPassword;
  }

  private _isSameAsCurrentPassword(currentPassword: string, newPassword: string): boolean {
    return currentPassword === newPassword;
  }

  private _goToLoginWithDelay(): void {
    setTimeout(() => {
      this._sessionService.logoutAndRedirect();
    }, redirectDelay);
  }

  private _showMessageAndLogin(message: string) {
    this._snackBarService.success(message, { duration: redirectDelay });
    this._goToLoginWithDelay();
  }
}

interface LoginChangePasswordState {
  details: {
    loginName: string;
    currentPassword: string;
    newPassword: string;
    newConfirmPassword: string;
  };
  passwordResetToken: string;
  messages: {
    changeSuccess: string;
    noMatch: string;
    newSameAsCurrent: string;
    invalidCurrentPassword: string;
  };
}

<div class="content-wrapper ext-login-box">
  <p class="agreement">
    <small>
      By logging into Miu Insights, you confirm that you will only access materials to which you are legally entitled. Any use of materials
      accessed through Miu Insights should be in adherence with the terms of your Miu license agreement.
    </small>
  </p>

  <form name="loginForm" (ngSubmit)="onSignIn()" #loginForm="ngForm">
    <mat-form-field>
      <input
        matInput
        type="text"
        class="ext-login-name"
        placeholder="Email"
        autofocus
        ignoreLimitedChars
        name="email"
        [(ngModel)]="state.details.email"
        required
      />
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        type="password"
        class="ext-password"
        placeholder="Password"
        name="password"
        ignoreLimitedChars
        [(ngModel)]="state.details.password"
        required
      />
    </mat-form-field>

    <div class="links">
      <a class="link" href="mailto:RMS_miuinsights@moodys.com"> Register </a>

      <a class="link ext-forgot-password" routerLink="./forgot-password"> Forgot Password </a>
    </div>
    <div class="buttons">
      <button mat-flat-button type="submit" class="radius radius-primary ext-login-button" [disabled]="loginForm.invalid">Login</button>
    </div>
  </form>
</div>

import { PermissionService } from '@shared/services';
import { USER_ACCOUNT } from '@shared/services/permission/access-zones.constant';
import { NavItem } from 'app/+nav/nav-item.interface';

export const accountMenu = (permissionService: PermissionService): NavItem[] => [
  {
    label: 'Organizations',
    icon: 'mdi-account-group',
    state: '/account/organizations',
    canRead: true,
  },
  { label: 'Users', icon: 'mdi-account', state: '/account/users', canRead: true },
  { label: 'Roles', icon: 'mdi-ship-wheel', state: '/account/roles', canRead: true },
  {
    label: 'Settings',
    icon: 'mdi-settings',
    state: '/account/config',
    canRead: permissionService.getGrants(USER_ACCOUNT).configure,
  },
  {
    label: 'Usage',
    svgIcon: 'usage',
    state: '/account/usage',
    canRead: permissionService.getGrants(USER_ACCOUNT).read,
  },
];

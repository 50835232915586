export enum ContractNavItemFilter {
  DATAVERSION,
  MODIFIEDBY,
  CREATEDBY,
  PUBLIC,
  STATUS,
  TYPE,
  EXPOSURETYPE,
  REGION,
  PERIL,
  LATESTREVISION,
  ONRISK,
}

import { Component, Input, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { DocsService, PermissionService, SessionService, TrackingService } from '@shared/services';
import { Profile } from '@shared/services/session/profile.interface';
import { CATEGORIES } from '@shared/services/tracking/tracking.const';
import { appDocsMenu } from 'app/app-docs-menu.constant';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CONSTANTS } from '@shared/constants';

import { NavService } from './nav.service';
import { NavItem } from './nav-item.interface';
import { appMenu } from '../app-menu.constant';

@Component({
  selector: 'mit-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnDestroy {
  @Input()
  set sidenav(sidenav: MatSidenav) {
    this.state.sidenavRef = sidenav;
  }
  public state: NavState = {
    menus: {},
    sidenavRef: undefined,
    fullName: undefined,
    company: undefined,
    version: CONSTANTS.latestSoftwareVersion,
  };

  private _unsubscribe: Subject<void> = new Subject();

  constructor(
    private _router: Router,
    private _navService: NavService,
    private _sessionService: SessionService,
    private _trackingService: TrackingService,
    private _docsService: DocsService,
    private _permissionService: PermissionService
  ) {
    this._navService
      .whenSessionReady$(() => [appMenu(this._permissionService), appDocsMenu(this._docsService)])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((menus: NavItem[][]) => this._setMenuState(menus));

    this._sessionService.session$.pipe(takeUntil(this._unsubscribe)).subscribe((profile: Profile) => {
      this.state.fullName = `${profile.firstName} ${profile.lastName}`;
      this.state.company = profile.company.name;
    });
  }

  public ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public track(name: string): void {
    this._trackingService.trackEvent(CATEGORIES.NAVMENU, { name });
  }

  public onClickListItem(): void {
    this.state.sidenavRef.close();
  }

  public changePassword(): void {
    this._router.navigateByUrl('/login/change-password');
    this.state.sidenavRef.close();
  }

  public logout(): void {
    this._sessionService.logoutAndRedirect();
    this.state.sidenavRef.close();
  }

  private _setMenuState(menus: NavItem[][]): void {
    const allMenus = menus[0].concat(menus[1]);
    this.state.menus.otherMenus = this._navService.getOtherMenus(allMenus);
    this.state.menus.yourMenus = this._navService.getYourMenus(allMenus);
  }
}

interface NavState {
  menus: { [name: string]: NavItem[] };
  sidenavRef: MatSidenav;
  fullName: string;
  company: string;
  version: string;
}

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TrackingModule } from '@shared/services/tracking';

import { APIService } from './api.service';

@NgModule({
  // eslint-disable-next-line
  imports: [HttpClientModule, TrackingModule],
  declarations: [],
  exports: [],
  providers: [APIService],
})
export class APIModule {}

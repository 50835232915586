import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { GlobalsModule } from '@shared/globals/globals.module';

import { DialogService } from './dialog.service';
import { PromptDialogComponent } from './+prompt-dialog';
import { InfoDialogComponent } from './+info-dialog';
import { DeleteDialogComponent } from './+delete-dialog';

@NgModule({
  imports: [FormsModule, CommonModule, MatDialogModule, MatButtonModule, MatCheckboxModule, GlobalsModule],
  declarations: [DeleteDialogComponent, PromptDialogComponent, InfoDialogComponent],
  exports: [],
  providers: [DialogService],
})
export class DialogModule {}

import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';

const noElementIdSetError = 'No elementId set';

@Injectable()
export class DynamicComponentService {
  constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

  public openComponentInNewWindow(elementId: string, title?: string, triggerPrint?: boolean): void {
    if (elementId) {
      setTimeout(() => {
        const currentDocument = this._removeScriptFromHtml(window.document).documentElement;
        const newWindow = window.open();
        newWindow.document.write(currentDocument.innerHTML);
        newWindow.document.body.innerHTML = document.getElementById(elementId).innerHTML;
        document.getElementById(elementId).innerHTML = '';

        if (title) {
          newWindow.document.title = title;
        }

        if (triggerPrint) {
          setTimeout(() => newWindow.print(), 1000);
        }
      }, 0);
    } else {
      console.error(noElementIdSetError);
    }
  }

  public createDynamicComponent(viewContainerRef: ViewContainerRef, dynamicComponent: any, data?: any) {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(dynamicComponent);
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as { data: any }).data = data;
  }

  private _removeScriptFromHtml(doc: Document): Document {
    const tags = doc.getElementsByTagName('script');

    for (let i = tags.length; i >= 0; i--) {
      if (tags[i]) {
        tags[i].parentNode.removeChild(tags[i]);
      }
    }

    return doc;
  }
}

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule } from '@shared/services/auth';

import { SecurityService } from './security.service';
import { AuthSmlModule } from '../auth-sml';

@NgModule({
  // eslint-disable-next-line
  imports: [HttpClientModule, AuthModule, AuthSmlModule],
  declarations: [],
  exports: [],
  providers: [SecurityService],
})
export class SecurityModule {}

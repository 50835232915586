import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgIdleModule } from '@ng-idle/core';

import { IdleService } from './idle.service';
import { SessionTimeoutDialogComponent } from './+session-timeout-dialog';
import { SessionModule } from '../session';

@NgModule({
  // eslint-disable-next-line
  imports: [CommonModule, NgIdleModule.forRoot(), MatButtonModule, MatDialogModule, MatBottomSheetModule, SessionModule],
  exports: [SessionTimeoutDialogComponent],
  declarations: [SessionTimeoutDialogComponent],
  providers: [IdleService],
})
export class IdleModule {}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/browser';
import { ENVIRONMENT } from 'environment';

import { AppModule } from './app/app.module';

if (ENVIRONMENT.production) {
  enableProdMode();
}

if (ENVIRONMENT.envName === 'production' || ENVIRONMENT.envName === 'preproduction') {
  Sentry.init({
    dsn: 'https://4bf78fce3d9f41efac6f7080cf1a5ccf@o382909.ingest.sentry.io/5254029',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new BrowserTracing({
        tracingOrigins: ['localhost', 'www.miuinsights.com', 'dev.miuinsights.com', 'preprod.miuinsights.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === 'xhr') {
        const data = {
          requestBody: hint.xhr.__sentry_xhr__.body,
          response: hint.xhr.response,
          responseUrl: hint.xhr.responseURL,
        };
        return { ...breadcrumb, data };
      }
      return breadcrumb;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.7,
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

<li
  routerLinkActive="active"
  [matTooltip]="state.menuItem.label"
  [matTooltipDisabled]="state.tooltipDisabled"
  matTooltipPosition="right"
  (click)="track(state.menuItem.label)"
>
  <div class="rounded-left-border"></div>

  <a [routerLink]="state.menuItem.state">
    <mat-icon class="nav-icon" [class.hidden]="state.menuItem.svgIcon" fontSet="mdi" [fontIcon]="state.menuItem.icon"></mat-icon>

    <mat-icon class="nav-icon svg" [class.hidden]="state.menuItem.icon" [svgIcon]="state.menuItem.svgIcon"></mat-icon>

    <div class="nav-label">{{ state.menuItem.label }}</div>
  </a>
</li>

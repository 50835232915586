export const TRUE = 'Yes';
export const FALSE = 'No';

export type MixpanelBoolean = 'Yes' | 'No';

export const toMixpanelBoolean = (condition: boolean): MixpanelBoolean => (condition ? TRUE : FALSE);

export const CATEGORIES = {
  NAVMENU: 'navMenu',
  DOCUMENTS: 'documents',
  CONTRACT: 'contracts',
  PROGRAMS: 'programs',
  PORTFOLIOS: 'portfolios',
  RISKDATA: 'riskdata',
  DIRECTORY: 'directory',
  DELETEPERIL: 'deletePeril',
  CREATEPERIL: 'createPeril',
  DELETEREGION: 'deleteRegion',
  CREATEREGION: 'createRegion',
  ADDTEMPLATE: 'addTemplate',
  CREATEPROGRAM: 'createProgram',
  FINALIZEPROGRAM: 'finalizeProgram',
  UPLOADRISKDATA: (type: string) => `upload${type}`,
  CHARACTERIZATIONPROFILE: 'characterizationProfile',
  ANALYSISRUN: 'analysisRun',
  PROGRAMFINALIZED: 'programFinalized',
  ANALYSISDELETED: 'analysisDeleted',
};

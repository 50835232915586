import { NgModule } from '@angular/core';

import { PermissionService } from './permission.service';

@NgModule({
  // eslint-disable-next-line
  imports: [],
  declarations: [],
  exports: [],
  providers: [PermissionService],
})
export class PermissionModule {}

import { NgModule } from '@angular/core';
import { AuthModule } from '@shared/services/auth';

import { WebSocketService } from './web-socket.service';

@NgModule({
  // eslint-disable-next-line
  imports: [AuthModule],
  declarations: [],
  exports: [],
  providers: [WebSocketService],
})
export class WebSocketModule {}

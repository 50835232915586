<div [class.maintenance-page]="!isDialog">
  <div mat-dialog-content>
    <div class="maintenance">
      <div class="maintenance--text">
        <h1 class="maintenance--text--header">Miu Insights needs a quick update.</h1>
        <h2 class="maintenance--text--subheader">Scheduled Maintenance</h2>

        <p>
          Please be advised that miuinsights.com is performing scheduled maintenance<span *ngIf="hasDates">
            during the time period below</span
          >.
        </p>
        <div *ngIf="hasDates">
          <p><strong>Starting:</strong> {{ startDate | date: dateFormat }}</p>
          <p><strong>Ending:</strong> {{ endDate | date: dateFormat }}</p>
        </div>
        <p *ngIf="isDialog">
          Miuinsights.com will be unavailable during this period. We recommend completing all analyses before the maintenance time.
        </p>
      </div>

      <div class="maintenance--image">
        <img alt="construction" src="assets/illustrations/zero-global-small-alt.svg" />
      </div>
    </div>
  </div>

  <div *ngIf="isDialog" mat-dialog-actions>
    <button mat-flat-button tabindex="1" matDialogClose class="radius radius-primary">Acknowledge</button>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PromptDialogData } from './prompt-dialog-data.interface';

@Component({
  templateUrl: 'prompt-dialog.component.html',
})
export class PromptDialogComponent {
  constructor(private _dialogRef: MatDialogRef<PromptDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: PromptDialogData) {}

  public affirmative(): void {
    this._dialogRef.close(true);
  }

  public negative(): void {
    this._dialogRef.close(false);
  }
}

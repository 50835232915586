import { NgModule } from '@angular/core';

import { QueryParamService } from './query-param.service';

@NgModule({
  // eslint-disable-next-line
  imports: [],
  declarations: [],
  exports: [],
  providers: [QueryParamService],
})
export class QueryParamModule {}

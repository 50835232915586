import { NgModule } from '@angular/core';

import { DocumentService } from './document.service';

@NgModule({
  // eslint-disable-next-line
  imports: [],
  declarations: [],
  exports: [],
  providers: [DocumentService],
})
export class DocumentModule {}

import { Injectable, OnDestroy } from '@angular/core';
import { CONTRACT_BUILDER, CONTRACT_USER_ANALYTICS, POTENTIAL_CLIENT } from '@shared/services/permission/access-zones.constant';
import { DocumentationLink } from 'environment';
import { includes, omit, omitBy } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SwaggerUI from 'swagger-ui';

import { AuthService } from '../auth/auth.service';
import { PermissionService } from '../permission/permission.service';
import { SessionService } from '../session/session.service';
import { DocsServiceToAccessZone } from './docs-service-to-access-zone.constant';
import { SwaggerMask } from './swagger-mask.class';
import { SwaggerResponseV2 } from './swagger-response-v2.interface';
import { SwaggerResponseV3 } from './swagger-response-v3.interface';

const domId = '#miu-documentation';
const defaultOptions = {
  dom_id: domId,
  filter: true,
  displayOperationId: true,
};

const docs = 'docs';
let masks: { [serviceName: string]: SwaggerMask };

@Injectable()
export class DocsService implements OnDestroy {
  private _unsubscribe: Subject<void> = new Subject();

  constructor(private _permissionService: PermissionService, private _sessionService: SessionService, private _authService: AuthService) {
    this._sessionService.createSession$.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
      masks = this._createMasks();
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  public canReadDocumentation(serviceName: string): boolean {
    const accessZone = DocsServiceToAccessZone[serviceName];
    return this._permissionService.getGrants(POTENTIAL_CLIENT).read
      ? true
      : accessZone
      ? this._permissionService.getGrants(accessZone).read
      : true;
  }

  public getUrl(serviceName: string) {
    return `/${docs}/${serviceName}`;
  }

  public get v2(): { [index: string]: (...args) => any } {
    const backendBaseUrlRe = /(.*):\/\/localhost:9000\//;
    return {
      overrideReqUrl: (url: string, scheme: string, endpointAndVersion: string): string =>
        url.replace(backendBaseUrlRe, this.v2.createOverrideUrl(scheme, endpointAndVersion)),
      createOverrideUrl: (scheme: string, endpointAndVersion: string): string =>
        `${scheme}://${this.v2.removeVersionAndEnsureTrailingSlash(endpointAndVersion)}`,
      removeVersionAndEnsureTrailingSlash: (endpointAndVersion: string): string => endpointAndVersion.replace(/\/v\d*$|$/, '/'),
      maskedJson: (mask: SwaggerMask, swaggerJson: SwaggerResponseV2): SwaggerResponseV2 => {
        swaggerJson.tags = swaggerJson.tags.filter((i) => !includes(mask.tags.join(','), i.name));
        swaggerJson.paths = omitBy(swaggerJson.paths, (value, key) => mask.paths.some((path) => new RegExp(path).test(key)));
        swaggerJson.definitions = omit(swaggerJson.definitions, mask.definitions);

        return swaggerJson;
      },
      ui: (serviceName: string, docLink: DocumentationLink, swaggerJson: SwaggerResponseV2) =>
        SwaggerUI({
          ...defaultOptions,
          spec: this.v2.maskedJson(this._getMask(serviceName), swaggerJson),
          requestInterceptor: (req) =>
            this._requestInterceptor(req, [
              (url) => this.v2.overrideReqUrl(url, ...this._getUrlParts(docLink.url)),
              (url) => this._securityServiceTransforms(url, docLink.displayName.toLowerCase()),
            ]),
        }),
    };
  }

  public get v3(): { [index: string]: (...args) => any } {
    const backendBaseUrlRe = /(.*):\/\/localhost\//;
    // eslint-disable-next-line
    const self = this;
    return {
      overrideReqUrl: (url: string, scheme: string, endpointAndVersion: string): string =>
        url.replace(backendBaseUrlRe, `${scheme}://${endpointAndVersion}/`),
      maskedJson: (mask: SwaggerMask, swaggerJson: SwaggerResponseV3): SwaggerResponseV3 => {
        swaggerJson.paths = self._omitTags(mask, swaggerJson);
        swaggerJson.paths = omitBy(swaggerJson.paths, (value, key) => mask.paths.some((path) => new RegExp(path).test(key)));
        swaggerJson.components.schemas = omit(swaggerJson.components.schemas, mask.definitions);

        return swaggerJson;
      },
      ui: (serviceName: string, docLink: DocumentationLink, swaggerJson: SwaggerResponseV3) =>
        SwaggerUI({
          ...defaultOptions,
          spec: this.v3.maskedJson(this._getMask(serviceName), swaggerJson),
          requestInterceptor: (req) =>
            this._requestInterceptor(req, [
              (url) => this.v3.overrideReqUrl(url, ...this._getUrlParts(docLink.url)),
              (url) => this._securityServiceTransforms(url, docLink.displayName.toLowerCase()),
            ]),
        }),
    };
  }

  private _omitTags(mask: SwaggerMask, swaggerJson: SwaggerResponseV3): any {
    const newPaths = {};

    for (const path in swaggerJson.paths) {
      if (swaggerJson.paths.hasOwnProperty(path)) {
        const verbsObj = swaggerJson.paths[path];
        Object.keys(verbsObj).forEach((verb) => {
          if (verbsObj[verb].tags.every((tag) => !mask.tags.includes(tag))) {
            const newVerbObj = {};
            newVerbObj[verb] = verbsObj[verb];
            newPaths[path] = Object.assign(newPaths[path] || {}, newVerbObj);
          }
        });
      }
    }
    return newPaths;
  }

  private _requestInterceptor(req: SwaggerUI.Request, urlTransforms: ((url: string) => string)[]): Promise<SwaggerUI.Request> {
    req.url = urlTransforms.reduce((acc, next) => next(acc), req.url);
    const promise = new Promise<SwaggerUI.Request>((resolve, reject) => {
      if (this._isNoAuthRoute(req.url)) {
        resolve(req);
      } else {
        if (this._authService.isAuthenticated()) {
          resolve(this._setAuthHeader(req));
        } else {
          this._sessionService
            .refreshSession()
            .then(() => resolve(this._setAuthHeader(req)))
            .catch(reject);
        }
      }
    });

    return this._workAroundSwaggerBug(promise, req.url);
  }

  private _setAuthHeader(req: SwaggerUI.Request): SwaggerUI.Request {
    req.headers.Authorization = this._authService.bearerAuthToken;
    return req;
  }

  private _workAroundSwaggerBug(promise: Promise<SwaggerUI.Request>, url): Promise<SwaggerUI.Request> {
    // IMP: https://rmsrisk.atlassian.net/browse/RIIQ-620
    promise['url'] = url;
    return promise;
  }

  private _isNoAuthRoute(url: string): boolean {
    const noAuthRoutes = ['oauth2/token', 'oauth2/revoke'];
    return noAuthRoutes.some((routeFragment) => url.indexOf(routeFragment) > -1);
  }

  private _getUrlParts(url: string): [string, string] {
    const [, scheme, endpointAndVersion] = url.match(/(.*):\/\/(.*)\/api-docs.*/);
    return [scheme, endpointAndVersion];
  }

  private _securityServiceTransforms(url: string, serviceDisplayName: string): string {
    const security = {
      serviceDisplayName: 'security',
      replacements: [{ re: /v\d*\/users\/tokens/, text: 'users/tokens' }],
    };

    return serviceDisplayName === security.serviceDisplayName
      ? security.replacements.reduce((acc, next) => acc.replace(next.re, next.text), url)
      : url;
  }

  private _getMask(serviceName: string): SwaggerMask {
    const defaultMask = 'defaultMask';
    return masks[serviceName] || masks[defaultMask];
  }

  private _createMasks(): { [serviceName: string]: SwaggerMask } {
    const userAnalyticsConfigure = this._permissionService.getGrants(CONTRACT_USER_ANALYTICS).configure;
    const contractBuilderConfigure = this._permissionService.getGrants(CONTRACT_BUILDER).configure;
    const userAnalyticsConfigureAnalytics = userAnalyticsConfigure
      ? new SwaggerMask()
      : new SwaggerMask(['analytics'], ['/analytics/analyses', '/analytics/analyses/{analysisUuid}'], []);

    const generalMigrations = new SwaggerMask(['migrations'], ['^/migrations'], []);

    const contractBuilderConfigureCluster = contractBuilderConfigure
      ? new SwaggerMask()
      : new SwaggerMask(['cluster'], ['/cluster/state', '/cluster/{clusterId}'], []);

    const contractBuilderConfigureQueue = contractBuilderConfigure ? new SwaggerMask() : new SwaggerMask(['queue'], ['/queue/resume'], []);

    const contractBuilderConfigureParser = contractBuilderConfigure
      ? new SwaggerMask()
      : new SwaggerMask(['parser'], ['/parser/primitive', '/parser/primitiveLog', '/parser/primitiveLogDetail'], []);

    return {
      defaultMask: generalMigrations,
      security: generalMigrations,
      analysis: SwaggerMask.merge(
        userAnalyticsConfigureAnalytics,
        generalMigrations,
        contractBuilderConfigureCluster,
        contractBuilderConfigureQueue
      ),
      contract: SwaggerMask.merge(generalMigrations, contractBuilderConfigureParser),
      exposurelibrary: generalMigrations,
      currency: generalMigrations,
      yltlibrary: generalMigrations,
      ciab: generalMigrations,
      eltgenerator: generalMigrations,
      autoimport: generalMigrations,
      document: generalMigrations,
      notifications: generalMigrations,
    };
  }
}

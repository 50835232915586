import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss'],
})
export class SessionTimeoutDialogComponent {
  public countdown$: Observable<number> = of(0);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { onTimeoutWarning$: EventEmitter<number> },
    private _dialogRef: MatDialogRef<SessionTimeoutDialogComponent>
  ) {
    this.countdown$ = this.data.onTimeoutWarning$.pipe(distinctUntilChanged());
  }

  public reset(): void {
    this._dialogRef.close(false);
  }

  public logout(): void {
    this._dialogRef.close(true);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { GlobalsModule } from '@shared/globals/globals.module';
import { DocsModule } from '@shared/services/docs';
import { DocumentModule } from '@shared/services/document';
import { PermissionModule } from '@shared/services/permission';
import { YearlossModule } from '@shared/services/yearloss';

import { HeaderComponent } from './header.component';
import { HelpMenuService } from './+help-menu/help-menu.service';
import { HelpMenuComponent } from './+help-menu/help-menu.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,

    GlobalsModule,
    DocumentModule,
    YearlossModule,
    PermissionModule,
    DocsModule,
  ],
  declarations: [HeaderComponent, HelpMenuComponent],
  exports: [HeaderComponent, HelpMenuComponent],
  providers: [HelpMenuService],
})
export class HeaderModule {}

import { HttpClient, HttpEventType, HttpHeaders, HttpProgressEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { alphaWithDashes } from '@shared/utilities/file';
import { isUndefined } from 'lodash';
import { Observable, of } from 'rxjs';
import { delay, filter, map, takeWhile } from 'rxjs/operators';

import { CloudUploadProgress } from './cloud-upload-progress.interface';
import { APIService } from '../api/api.service';

const delayTime = 60000;
const malwareMsg = '(possibly due to malware)';

export const S3MetadataHeaderKey = 'X-Amz-Meta';

@Injectable()
export class CloudService {
  constructor(private _http: HttpClient, private _apiService: APIService) {}

  public getCompressedFileFromS3(link: string): Observable<string> {
    const headers = new HttpHeaders();
    return this._apiService.get(link, {
      headers,
      userDefinedOptionsOnly: true,
      responseType: 'application/octet-stream',
    });
  }

  public getFileFromS3(link: string, byteRange?: { from: number; to: number }): Observable<string> {
    let headers = new HttpHeaders();

    if (byteRange && !isUndefined(byteRange.from) && !isUndefined(byteRange.to)) {
      headers = headers.set('range', `bytes=${byteRange.from}-${byteRange.to}`);
    }

    return this._apiService.get(link, {
      headers,
      userDefinedOptionsOnly: true,
      responseType: 'text',
    });
  }

  public uploadFileToS3(file: File, url: string, extraMetadataHeaders?: { [key: string]: string }): Observable<CloudUploadProgress> {
    let headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/octet-stream')
      .set('Content-Disposition', `'attachment; filename="${alphaWithDashes(file.name)}"'`);

    if (extraMetadataHeaders) {
      Object.keys(extraMetadataHeaders).forEach((key) => {
        headers = headers.set(`${S3MetadataHeaderKey}-${key}`, extraMetadataHeaders[key]);
      });
    }

    // IMP: Refactor api service to allow use of observe: 'events' to be passed through #5656
    // return this._apiService.put(url, file, { headers, userDefinedOptionsOnly: true });
    return this._http
      .request(
        new HttpRequest('PUT', url, file, {
          headers,
          reportProgress: true,
        })
      )
      .pipe(
        takeWhile((event) => event.type !== HttpEventType.Response),
        filter((event) => event.type === HttpEventType.UploadProgress),
        map((event: HttpProgressEvent) => ({
          uploaded: event.loaded,
          total: event.total,
          progress: Math.round((event.loaded / event.total) * 100),
        }))
      );
  }

  public checkUploadStatus(arg?: any): Observable<any> {
    return of(arg).pipe(delay(delayTime));
  }

  public get malwareMsg(): string {
    return malwareMsg;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService, SnackbarService } from '@shared/services';

const redirectDelay = 3000;
@Component({
  templateUrl: 'login-forgot-password.component.html',
  styleUrls: ['login-forgot-password.component.scss'],
})
export class LoginForgotPasswordComponent {
  public state: LoginForgotPasswordState = {
    details: {
      email: '',
    },
    messages: {
      resetLinkSent: 'A reset link has been sent to your email address.',
    },
  };

  constructor(private _securityService: SecurityService, private _router: Router, private _snackBarService: SnackbarService) {}

  public onResetPassword(): void {
    this._securityService.forgotPassword(this.state.details.email).then((res) => {
      this._snackBarService.success(this.state.messages.resetLinkSent, {
        duration: redirectDelay,
      });
      setTimeout(() => this._router.navigateByUrl('/login'), redirectDelay);
    });
  }
}

interface LoginForgotPasswordState {
  details: {
    email: string;
  };
  messages: {
    resetLinkSent: string;
  };
}

import { NgModule } from '@angular/core';

import { LimitedCharsDirective } from './limitedChars.directive';

@NgModule({
  // eslint-disable-next-line
  imports: [],
  declarations: [LimitedCharsDirective],
  exports: [LimitedCharsDirective],
  providers: [],
})
export class LimitedCharsModule {}

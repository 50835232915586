import { Injectable } from '@angular/core';
import { ENVIRONMENT } from 'environment';

import { SessionService } from '../session/session.service';
import { Grants } from './grants.interface';

@Injectable()
export class PermissionService {
  private _permissionNamesToNumbersMap = {
    READ: 1,
    UPDATE: 2,
    CREATE: 4,
    HIDE: 8,
    FINALIZE: 8,
    PUBLISH: 16,
    PURGE: 32,
    CONFIGURE: 64,
  };

  constructor(private _sessionService: SessionService) {}

  public getGrants(accessZone: string): Grants {
    // allow all on UCICD envs
    if (!ENVIRONMENT.isMiuEnvironment) {
      return {
        read: true,
        update: true,
        create: true,
        publish: true,
        hide: true,
        finalize: true,
        purge: true,
        configure: true,
      };
    }

    return {
      read: this._can('READ', accessZone),
      update: this._can('UPDATE', accessZone),
      create: this._can('CREATE', accessZone),
      publish: this._can('PUBLISH', accessZone),
      hide: this._can('HIDE', accessZone),
      finalize: this._can('FINALIZE', accessZone),
      purge: this._can('PURGE', accessZone),
      configure: this._can('CONFIGURE', accessZone),
    };
  }

  private _isPermitted(accessZone: string, permission: number): boolean {
    return this._includes(this._usersPermissionLevel(accessZone), permission);
  }

  private _usersPermissionLevel(accessZone: string): number {
    // eg: "catinabox":127,"catinaboxjpeq":1
    return this._sessionService.permissionLevels[accessZone.toLowerCase()];
  }

  private _getPermission(permissionName: string): number {
    return this._permissionNamesToNumbersMap[permissionName];
  }

  private _includes(permissionLevel: number, permission: number): boolean {
    return (
      permissionLevel !== undefined &&
      permission > 0 &&
      // Bitwise operator in parentheses because equality operator has higher precedence
      // eslint-disable-next-line no-bitwise
      (permissionLevel & permission) === permission
    );
  }

  private _can(permission: string, accessZone: string): boolean {
    return this._sessionService.hasSession() && this._isPermitted(accessZone, this._getPermission(permission));
  }
}

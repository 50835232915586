import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { CONSTANTS } from '@shared/constants';
import { AuthService, AuthSmlService, DialogService, SecurityService, SessionService, SnackbarService } from '@shared/services/';
import { SmlProfile } from '@shared/services/auth-sml/auth-sml.interface';
import { Profile } from '@shared/services/session/profile.interface';
import { NavService } from 'app/+nav/nav.service';
import { MaintenanceDialogData } from 'app/maintenance/maintenance-dialog-data.interface';
import { MaintenanceComponent } from 'app/maintenance/maintenance.component';
import { ENVIRONMENT } from 'environment';
import { take } from 'rxjs/operators';

const EmailOrPasswordIncorrect = 'The email or password is incorrect';
const TroubleLoggingIn = "Looks like you're having difficulty logging in";
const ResetCredentials = 'Reset Credentials';
const returnUrlStr = 'returnUrl';

@Component({
  templateUrl: 'login-page.component.html',
  styleUrls: ['login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  public state: LoginPageState = {
    validated: false,
    count: 1,
    details: {
      email: '',
      password: '',
    },
  };

  constructor(
    private _securityService: SecurityService,
    private _sessionService: SessionService,
    private _authService: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _snackBarService: SnackbarService,
    private _navService: NavService,
    private _dialogService: DialogService,
    private _authSmlService: AuthSmlService
  ) {}

  public ngOnInit(): void {
    if (window.location.pathname === '/login' && this._sessionService.hasSession()) {
      this._navService.goToHome();
    } else {
      this._sessionService.logout();
    }
  }

  public onSignIn(): void {
    const authService = ENVIRONMENT.isMiuEnvironment ? this._authService : this._authSmlService;
    authService.login(this.state.details.email, this.state.details.password).then(
      (res): void => {
        const loggedIn = ENVIRONMENT.isMiuEnvironment ? res && res.access_token : res;
        if (loggedIn) {
          this.state.validated = true;
          this._route.queryParams.pipe(take(1)).subscribe((params) =>
            this._initializeSession(params).then(() => {
              const data: MaintenanceDialogData = {
                startDate: CONSTANTS.maintenance.startDate,
                endDate: CONSTANTS.maintenance.endDate,
              };

              // IMP: [RIIQ-542] BE to provide a notification to determine maintenance window
              if (this._canPromptMaintenanceDialog(data.endDate)) {
                this._dialogService.open(MaintenanceComponent, {
                  disableClose: true,
                  closeOnNavigation: false,
                  data,
                });
              }
            })
          );
        } else {
          this._onSignInError(res);
        }
      },
      (error: any): void => this._onSignInError(error)
    );
  }

  private _onSignInError(error: any): void {
    if (error && error['status'] === 0) {
      this._snackBarService.error(CONSTANTS.unhandledErrorMsg);
    } else {
      if (this.state.count > 5) {
        const loginTroubleSnackBar = this._snackBarService.info(TroubleLoggingIn, null, ResetCredentials);

        loginTroubleSnackBar.onAction().subscribe(() => {
          this._router.navigateByUrl('login/forgot-password').then(() => {
            loginTroubleSnackBar.dismiss();
          });
        });
      } else {
        this._snackBarService.error(EmailOrPasswordIncorrect);
      }
      this.state.count++;
    }
  }

  private _initializeSession(params: Params): Promise<void> {
    return this._securityService.getProfile().then(
      (profile: Profile | SmlProfile): void => {
        this._sessionService.initSession(profile);
        if (params && params[returnUrlStr]) {
          const { returnUrl, ...paramsWithoutReturnUrl } = params;
          const options: NavigationExtras = {
            queryParams: paramsWithoutReturnUrl,
          };

          this._router.navigate([params[returnUrlStr]], options);
        } else {
          this._navService.goToHome();
        }
      },
      (error) => error
    );
  }

  private _canPromptMaintenanceDialog(scheduledEndDate: Date): boolean {
    const isTodayGreaterThanScheduledEndDate = new Date() > scheduledEndDate;
    return !isTodayGreaterThanScheduledEndDate;
  }
}

interface LoginPageState {
  validated: boolean;
  count: number;
  details: {
    email: string;
    password: string;
  };
}

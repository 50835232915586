<h1 mat-dialog-title>Session Timeout</h1>

<div mat-dialog-content>
  <h5>
    You are about to be logged out due to inactivity
    <span *ngIf="(countdown$ | async) > 0"> in {{ countdown$ | async }} seconds</span>
  </h5>
</div>

<div mat-dialog-actions>
  <button mat-flat-button (click)="reset()" tabindex="1" class="radius radius-primary">Continue</button>

  <button mat-flat-button (click)="logout()" tabindex="2" class="radius radius-plain">Logout</button>
</div>

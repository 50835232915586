import { EventEmitter, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DEFAULT_INTERRUPTSOURCES, Idle, InterruptSource } from '@ng-idle/core';
import { Interrupt } from '@ng-idle/core/lib/interrupt';
import { isUndefined } from 'lodash';
import { filter } from 'rxjs/operators';

import { SessionTimeoutDialogComponent } from './+session-timeout-dialog';
import { SessionService } from '../session/session.service';

const sessionExpiryIdle = 3600;
const sessionExpiryTimeout = 60;

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  constructor(
    private _idle: Idle,
    private _sessionService: SessionService,
    private _dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) {}

  public init(returnUrl = window.location.pathname.slice(1), idleTime = sessionExpiryIdle, expiryTimeout = sessionExpiryTimeout): void {
    this._idle.setIdle(idleTime);
    this._idle.setTimeout(expiryTimeout);
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this._idle.watch();
    this._idle.onIdleStart.subscribe(() => this._openSessionTimeoutDialogDialog(returnUrl));
  }

  public get isRunning(): boolean {
    return this._idle.isRunning();
  }

  public stop(): void {
    this._idle.stop();
  }

  public interrupt(): void {
    this._idle.interrupt();
  }

  private _setInterrupts(sources: InterruptSource[]): Interrupt[] {
    return this._idle.setInterrupts(sources);
  }

  private get _onTimeoutWarning(): EventEmitter<number> {
    return this._idle.onTimeoutWarning;
  }

  private get _onTimeout(): EventEmitter<number> {
    return this._idle.onTimeout;
  }

  private _openSessionTimeoutDialogDialog(returnUrl: string): void {
    this._dialog.closeAll();
    this._bottomSheet.dismiss();

    const sessionTimeoutDialog = this._dialog.open(SessionTimeoutDialogComponent, {
      data: { onTimeoutWarning$: this._onTimeoutWarning },
      disableClose: true,
    });

    this._setInterrupts([]);
    this._onTimeout.subscribe(() => sessionTimeoutDialog.close(true));

    sessionTimeoutDialog
      .afterClosed()
      .pipe(filter((expire) => !isUndefined(expire)))
      .subscribe((expire: boolean) => {
        if (expire) {
          this._idle.stop();
          this._sessionService.logoutAndRedirect(returnUrl);
        } else {
          this.init(returnUrl);
        }
      });
  }
}

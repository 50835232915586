import { NgModule } from '@angular/core';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AuthService } from './auth.service';

@NgModule({
  // eslint-disable-next-line
  imports: [OAuthModule.forRoot()],
  declarations: [],
  exports: [],
  providers: [AuthService],
})
export class AuthModule {}

import { AfterViewInit, Component, OnDestroy } from '@angular/core';

const heroImages = ['london', 'nyc', 'dc'];
const nextIndex = (currentIndex) => {
  const next = currentIndex + 1;
  return next > heroImages.length - 1 ? 0 : next;
};

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  providers: [],
})
export class LoginComponent implements AfterViewInit, OnDestroy {
  public image = heroImages[0];
  public altImage = '';

  private _isFirstImageTime = true;
  private _interval;
  private _heroIndex = heroImages.findIndex((i) => i === this.image);

  public ngAfterViewInit(): void {
    this._interval = setInterval(() => {
      const previousIndex = this._heroIndex;
      this._heroIndex = nextIndex(this._heroIndex);

      this.image = this._isFirstImageTime ? `${heroImages[this._heroIndex]}` : `${heroImages[previousIndex]} fadeout`;
      this.altImage = !this._isFirstImageTime ? `${heroImages[this._heroIndex]}` : `${heroImages[previousIndex]} fadeout`;

      this._isFirstImageTime = !this._isFirstImageTime;
    }, 6000);
  }

  public get currentYear(): number {
    return new Date().getFullYear();
  }

  public ngOnDestroy(): void {
    clearInterval(this._interval);
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  templateUrl: 'snackbar.component.html',
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { text: string; action?: string },
    @Inject(MatSnackBarRef) public snackbarRef: MatSnackBarRef<SnackbarComponent>
  ) {}
}

import { PermissionService } from '@shared/services';
import {
  CAT_IN_A_BOX,
  CONTRACT,
  CONTRACT_BUILDER,
  EP_TO_ELT,
  GENERAL_DOCUMENT,
  MIU_RELEASE,
  PRICING_DOCUMENT,
  PUBLIC_CONTRACT,
  USER_ACCOUNT,
} from '@shared/services/permission/access-zones.constant';

import { NavItem } from './+nav/nav-item.interface';

export const appMenu = (permissionService: PermissionService): NavItem[] => [
  {
    description: 'Find 144a deals and documents.',
    label: 'Directory',
    icon: 'mdi-format-list-bulleted-square',
    isOther: false,
    state: 'contracts',
    canRead: permissionService.getGrants(CONTRACT).read || permissionService.getGrants(PUBLIC_CONTRACT).read,
  },
  {
    description: 'Documentation for software.',
    label: 'Downloads',
    icon: 'mdi-cloud-download',
    isOther: false,
    state: 'download',
    canRead:
      permissionService.getGrants(GENERAL_DOCUMENT).read ||
      permissionService.getGrants(PRICING_DOCUMENT).read ||
      permissionService.getGrants(MIU_RELEASE).read,
  },
  {
    label: 'EP to ELT',
    description: 'Convert OEP curves to ELTs.',
    icon: 'mdi-chart-line',
    isOther: false,
    state: 'epelt',
    canRead: permissionService.getGrants(EP_TO_ELT).read,
  },
  {
    description: 'A parametric trigger modeler.',
    label: 'Cat In A Box',
    icon: 'mdi-earth',
    isOther: false,
    state: 'ciab',
    canRead: permissionService.getGrants(CAT_IN_A_BOX).read,
  },
  {
    description: 'Structure programs and portfolios.',
    label: 'Contracts',
    icon: 'mdi-layers',
    isOther: false,
    state: 'contract',
    canRead: permissionService.getGrants(CONTRACT_BUILDER).read,
  },
  {
    description: 'Add clients to Miu Insights.',
    label: 'User Accounts',
    icon: 'mdi-shield-key-outline',
    isOther: true,
    state: 'account',
    canRead: permissionService.getGrants(USER_ACCOUNT).read,
  },
  {
    description: 'Tracks analysis and compute usage.',
    isOther: true,
    label: 'Usage',
    svgIcon: 'usage',
    state: 'usage',
    canRead: permissionService.getGrants(CONTRACT_BUILDER).read,
  },
];

import { Injectable } from '@angular/core';
import { ENVIRONMENT } from 'environment';
import mixpanel from 'mixpanel-browser';

import { SessionService } from '../session/session.service';

@Injectable()
export class TrackingService {
  constructor(private _sessionService: SessionService) {}

  public trackEvent(key: string, data: TrackingDataInterface): void {
    if (ENVIRONMENT.tracking.track) {
      mixpanel.track(key, data);
    }
  }

  public setupTracking(): void {
    mixpanel.init(ENVIRONMENT.tracking.mixpanelAccessToken);
    mixpanel.identify(`${this._sessionService.company?.uuid}_${this._sessionService.userUuid}`);

    mixpanel.register_once({
      userId: this._sessionService.userUuid,
      organization: this._sessionService.company?.name,
    });

    mixpanel.people.set_once({
      'First Login Date': new Date().toISOString(),
      'Total Logins': 0,
    });
    mixpanel.people.set({
      'Last Login Date': new Date().toISOString(),
    });
    mixpanel.people.increment('Total Logins');
  }
}

export interface TrackingDataInterface {
  [index: string]: number | string | string[];
}

import { DataTableItem } from '@shared/components/data-table/data-table-item.interface';

import { Portfolio } from '../portfolio.interface';
import { Program } from '../program.class';
import { ExposureWithRiskDataProps } from '../risk-data.interface';

type ContractPageTableItem<T> = T & Pick<DataTableItem, 'actions' | 'chips'>;

export type RiskDataTableItem = ContractPageTableItem<ExposureWithRiskDataProps> & {
  displayCreatedAt: string;
  displayDataVersionId: string;
  dataVersionIds: string[];
  displayExposureType: string;
};
export type ProgramTableItem = ContractPageTableItem<Program> & {
  displayModifiedAt: string;
  displayDataVersions: string;
};
export type PortfolioTableItem = ContractPageTableItem<Portfolio> & {
  displayModifiedAt: string;
  displayDataVersions: string;
};
export type AnyPageDataTableItem = RiskDataTableItem | ProgramTableItem | PortfolioTableItem;

export enum RiskDataTableItemProp {
  DESCRIPTION = 'description',
  REGIONCODE = 'regionCode',
  PERILCODE = 'perilCode',
  DISPLAYCREATEDBY = 'displayCreatedBy',
  DISPLAYCREATEDAT = 'displayCreatedAt',
  DISPLAYDATAVERSIONID = 'displayDataVersionId',
  DATAVERSIONIDS = 'dataVersionIds',
  PUBLICSTRING = 'publicString',
  EXPOSURETYPE = 'exposureType',
  DISPLAYEXPOSURETYPE = 'displayExposureType',
  CURRENCY = 'unitCode',
}

export enum ProgramTableItemProp {
  NAME = 'name',
  DISPLAYSOURCE = 'displaySource',
  DISPLAYYEAR = 'displayYear',
  MODIFIEDBYNAME = 'modifiedByName',
  DISPLAYMODIFIEDAT = 'displayModifiedAt',
  STATUS = 'status',
  PUBLICSTRING = 'publicString',
  DISPLAYDATAVERSIONS = 'displayDataVersions',
}

export enum PortfolioTableItemProp {
  NAME = 'name',
  MODIFIEDBYNAME = 'modifiedByName',
  DISPLAYMODIFIEDAT = 'displayModifiedAt',
  DISPLAYDATAVERSIONS = 'displayDataVersions',
}

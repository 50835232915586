import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { ValidationError } from 'src/app/contract/validation-error.interface';

@Injectable()
export class ValidatorService {
  public createValidatorFn(fieldName: string, errors: any): ValidatorFn {
    return (control): { [index: string]: { value: string } | null } => {
      const error = {};
      error[fieldName] = { value: errors[fieldName] };
      return errors[fieldName] ? error : null;
    };
  }

  public updateAllFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      formGroup.get(field).markAsDirty();
      formGroup.get(field).updateValueAndValidity({ emitEvent: false });
    });
  }

  public assignAndPruneErrors(errorObj: { [index: string]: string }, newErrors: ValidationError[]): void {
    newErrors.forEach((e) => {
      errorObj[e.type] = e.message;
    });

    Object.keys(errorObj).forEach((key) => {
      errorObj[key] = newErrors.map((e) => e.type).indexOf(key) > -1 ? errorObj[key] : undefined;
    });
  }
}

<div *ngIf="helpMenuItems.length > 0">
  <mat-menu #helpMenu>
    <button *ngFor="let item of helpMenuItems" mat-menu-item [ngClass]="['ext-help-menu-item', item.label]" (click)="goTo(item)">
      {{ item.label }}
    </button>
  </mat-menu>

  <button
    mat-icon-button
    [matMenuTriggerFor]="helpMenu"
    [class.open]="menuTrigger.menuOpen"
    #menuTrigger="matMenuTrigger"
    class="ext-help-menu-icon"
  >
    <mat-icon>info</mat-icon>
  </button>
</div>

import { FilePathInfo } from '@shared/interfaces';

export const parseFileName = (str: string): FilePathInfo => {
  const completeFileName = /([^\\\/:*?\"<>|]+)$/;
  const ext = /[.][\w]+$/;
  const path: FilePathInfo = {
    fileName: undefined,
    completeFileName: undefined,
    fileExtension: undefined,
  };

  if (!str) {
    return path;
  }

  const fileNameMatch = completeFileName.exec(str);
  if (fileNameMatch !== null) {
    path.completeFileName = fileNameMatch[0];
  } else {
    path.completeFileName = '';
  }

  const extensionMatch = ext.exec(str);
  if (extensionMatch !== null) {
    path.fileExtension = extensionMatch[0].toLowerCase();
  } else {
    path.fileExtension = '';
  }

  path.fileName = path.completeFileName.replace(/[.][\w]+$/, '');

  return path;
};

// See https://material.angular.io/components/dialog/api for MatDialogRef options and methods
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { StorageLike } from '@shared/storage/storage-like.interface';
import { capitalize } from '@shared/utilities/case';
import { assign, cloneDeep } from 'lodash';
import { of } from 'rxjs';

import { PromptDialogData } from './+prompt-dialog/prompt-dialog-data.interface';
import { PromptDialogComponent } from './+prompt-dialog';
import { InfoDialogComponent } from './+info-dialog';
import { DeleteDialogComponent } from './+delete-dialog';

@Injectable()
export class DialogService {
  constructor(public dialog: MatDialog) {}

  public open(component: any, options?: MatDialogConfig): MatDialogRef<any> {
    return this.dialog.open(component, this._mergeConfig(options));
  }

  public prompt(
    message: string,
    title = 'Close',
    actions: { affirmative: string } = { affirmative: 'Cancel' },
    lines?: string[]
  ): MatDialogRef<any> {
    const data: PromptDialogData = { title, message, actions, lines };
    return this.open(PromptDialogComponent, { data });
  }

  public info(title: string, lines: string[]): MatDialogRef<any> {
    const data = { title, lines };
    return this.open(InfoDialogComponent, { data });
  }

  public openDeleteWarning(store?: StorageLike<any>, options: MatDialogConfig = { data: {} }): MatDialogRef<any> {
    options.data.action = options.data.action || 'delete';
    return this.openWarning(DeleteDialogComponent, store, options);
  }

  public openWarning(
    component,
    store?: StorageLike<any>,
    options: MatDialogConfig = { data: { action: '', type: '' } }
  ): MatDialogRef<any> {
    const neverShowFlag = `neverShow${capitalize(options.data.action)}${capitalize(options.data.type)}Dialog`;
    let dialog;
    const neverShow = store?.getItem(neverShowFlag);
    if (!neverShow) {
      const localOptions = cloneDeep(options);
      localOptions.data.neverShow = neverShow;
      dialog = this.open(component, localOptions);
      dialog.afterClosed().subscribe((closeData: any): void => {
        if (closeData) {
          store?.setItem(neverShowFlag, closeData.neverShow);
        }
      });
    } else {
      dialog = { afterClosed: () => of(true) };
    }
    return dialog;
  }

  private _mergeConfig(options: MatDialogConfig = {}) {
    return assign(
      {
        hasBackdrop: true,
      },
      options
    );
  }
}

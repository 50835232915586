import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { UUID } from '@shared/interfaces/uuid.type';
import { ENVIRONMENT } from 'environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIService } from '../api/api.service';
import { DocumentByProgramUuid } from './document-by-program-uuid.interface';
import { DocumentByUuid } from './document-by-uuid.interface';
import { DocumentItem } from './document-item.interface';
import { DocumentNewUpdateResponse } from './document-new-update-response.interface';
import { DocumentResponse } from './document-response.interface';
import { DocumentTag } from './document-tag.interface';
import { DocumentType } from './document-type.interface';
import { DocumentUploadUrlResponse } from './document-upload-url-response.interface';
import { ProgramDocument } from './program-document.interface';

@Injectable()
export class DocumentService {
  public refreshDocumentList$: Subject<string> = new Subject<string>();

  constructor(private _apiService: APIService) {}

  public programDocs(programId: number): Observable<ProgramDocument[]> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.contractsDirectoryUrl.docs.programDocs(programId)}`;
    return this._apiService.get(url);
  }

  public newContractDoc(
    docType: string,
    fileId: string,
    displayName: string,
    contractIds: number[],
    fileName: string
  ): Observable<{ name: string }> {
    const postData = {
      docType,
      fileId,
      displayName,
      contractIds,
      fileName,
    };
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.contractsDirectoryUrl.docs.newDoc}`;
    return this._apiService.post(url, postData);
  }

  public assignDoc(contractId: number, docName: string, docTypeCode: string): Observable<{ id: number }> {
    const postData = { name: docName, typeCode: docTypeCode };
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.contractsDirectoryUrl.contracts.assignDoc(contractId)}`;
    return this._apiService.post(url, postData);
  }

  public getCharacterizationProfileSignedLink(contractId: number, contractVersion: string): Observable<{ url: string }> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.contractsDirectoryUrl.contracts.profile(contractId, contractVersion)}`;
    return this._apiService.get(url);
  }

  public getSignedLink(docId: number): Observable<{ url: string }> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.fileUrl.signedLink(docId)}`;
    return this._apiService.get(url);
  }

  public delete(docId: number): Observable<void> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.delete(docId)}`;
    return this._apiService.delete(url);
  }

  public byType(documentType: string, approved: boolean, lastn?: number, tag?: string): Promise<DocumentItem[]> {
    const status = approved ? 'approved' : 'unapproved';
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.byType(documentType, status)}`;
    let params: HttpParams = new HttpParams();

    if (lastn) {
      params = params.append('lastn', lastn.toString());
    }

    if (tag) {
      params = params.append('tag', tag);
    }

    return this._apiService
      .get(url, { params })
      .toPromise()
      .then(
        (res) => res,
        (error) => error
      );
  }

  public byTypeAndName(documentType: string, documentName: string): Promise<DocumentResponse> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.byTypeAndName(documentType, documentName)}`;
    return this._apiService
      .get(url)
      .toPromise()
      .then((res) => res);
  }

  public versions(docId: number): Promise<any> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.versions(docId)}`;
    return this._apiService
      .get(url)
      .toPromise()
      .then((res) => res);
  }

  public toggleHide(docId: number, hidden: boolean): Promise<any> {
    const postData = { hidden };
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.singleOp(docId)}`;
    return this._apiService
      .put(url, postData)
      .toPromise()
      .then((res) => res);
  }

  public approve(docId: number, version: number): Promise<any> {
    const postData = { approved: true };
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.singleOpRevision(docId, version)}`;
    return this._apiService
      .put(url, postData)
      .toPromise()
      .then((res) => res);
  }

  public rename(docId: number, newName: string): Promise<any> {
    const postData = { name: newName };
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.rename(docId)}`;
    return this._apiService
      .post(url, postData)
      .toPromise()
      .then((res) => res);
  }

  public names(docType: string): Promise<any> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.names}`;
    const params: HttpParams = new HttpParams().set('docType', docType);
    return this._apiService
      .get(url, { params })
      .toPromise()
      .then((res) => res);
  }

  public newDoc(
    fileId: string,
    docName: string,
    docTypeCode: string,
    fileName: string,
    approved: boolean,
    tags: string[]
  ): Promise<DocumentNewUpdateResponse> {
    const postData = { fileId, docName, docTypeCode, fileName, tags, approved };
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.newDoc}`;
    return this._apiService
      .post(url, postData)
      .toPromise()
      .then((res: DocumentNewUpdateResponse) => res);
  }

  public updateDoc(id: number, fileId: string, fileName: string, approved: boolean): Promise<DocumentNewUpdateResponse> {
    const postData = { fileId, fileName, approved };
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.updateDoc(id)}`;
    return this._apiService
      .post(url, postData)
      .toPromise()
      .then((res: DocumentNewUpdateResponse) => res);
  }

  public types(permissionNameFilter?: string): Observable<DocumentType[]> {
    const url = `${ENVIRONMENT.baseUrl.files}${CONSTANTS.filesUrl.documentsTypes}`;
    return this._apiService
      .get(url)
      .pipe(
        map((types: DocumentType[]) =>
          permissionNameFilter ? types.filter((type) => type.permissionName === permissionNameFilter) : types
        )
      );
  }

  public tags(): Observable<DocumentTag[]> {
    const url = `${ENVIRONMENT.baseUrl.files}${CONSTANTS.filesUrl.documentsTags}`;
    return this._apiService.get(url);
  }

  public tagsByType(documentType: string): Observable<DocumentTag[]> {
    return this.tags().pipe(map((tags) => this._filterByTags(tags, documentType)));
  }

  public get docTypePermissions(): Promise<any> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.allPermissions}`;
    return this._apiService
      .get(url)
      .toPromise()
      .then((res) => res);
  }

  public getUploadUrl(): Observable<DocumentUploadUrlResponse> {
    const url = `${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.uploadUrl}`;
    return this._apiService.post(url, null);
  }

  public docsByProgramUuid(uuid: UUID): Observable<DocumentByProgramUuid[]> {
    return this._apiService.get(`${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.byProgramUuid(uuid)}`);
  }

  public doc(uuid: UUID): Observable<DocumentByUuid> {
    return this._apiService.get(`${ENVIRONMENT.baseUrl.document}${CONSTANTS.docsUrl.doc(uuid)}`);
  }

  private _filterByTags(tags: DocumentTag[], documentType: string): DocumentTag[] {
    const filteredTags = tags.filter((item) => item.code.split(':')[0] === documentType);
    return filteredTags?.length > 0 ? filteredTags : [];
  }
}

import { Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd, Params, Router } from '@angular/router';
import { RecentActions, RecentActionsLocalStore } from '@shared/services/recent-actions/recent-actions.interface';
import { LocalStore } from '@shared/storage/local-store.class';
import { capitalize } from '@shared/utilities/case';
import { bufferTime } from 'rxjs/operators';

import { RECENT_ACTIONS, RISKDATA, RISK_DATA, RISK_DATA_IMPORT } from './recent-actions.constant';
import { PermissionService } from '../permission/permission.service';
import { downloadMenu } from '../../../download/download-menu.constant';
import { ENVIRONMENT } from '../../../../environments';

const pathsToExclude = ['account', 'home', 'login'];
const paramsToExcludeRegEx = new RegExp(/(u?u?id)/gim);
const titlesToExclude = ['Directory', 'Home', 'User Accounts', 'User Management'];

@Injectable({
  providedIn: 'root',
})
export class RecentActionsService {
  public localStore = new LocalStore<RecentActionsLocalStore>(window.localStorage, RECENT_ACTIONS);
  private _isRunning = false;

  constructor(private _router: Router, private _permissionService: PermissionService) {}

  public init(): void {
    if (!this._isRunning) {
      this._isRunning = true;
      this._subscribeToRouterEvents();
    }
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  public get recentActions(): RecentActions[] {
    return this.localStore.getItem(RECENT_ACTIONS) || [];
  }

  private _canAddUrl(recentActions: RecentActions[], urlWithoutQueryString: string): boolean {
    const rootPath = urlWithoutQueryString.split('/')[1];

    return this._isUniqueAction(recentActions, urlWithoutQueryString) && !pathsToExclude.includes(rootPath);
  }

  private _filterTitle(title: string[]): string[] {
    return title.filter((segment, index) => this._cleanTitleSegments(title, segment, index));
  }

  private _cleanTitleSegments(title: string[], segment: string, index: number): boolean {
    return (
      (title.indexOf(capitalize(segment)) === index || title.indexOf(capitalize(segment)) === -1) &&
      segment !== undefined &&
      !titlesToExclude.includes(capitalize(segment))
    );
  }

  private _formatFullTitle(title: string[], separator: string, event: NavigationEnd): string {
    return event.url.includes('riskdata') ? this._formatRiskDataTitle(title, separator) : title.reverse().join(separator);
  }

  private _formatRiskDataTitle(title: string[], separator: string): string {
    if (title.includes(capitalize(RISKDATA))) {
      title[title.indexOf(capitalize(RISKDATA))] = RISK_DATA;
    }

    if (title.includes(RISK_DATA_IMPORT)) {
      const last = title[title.length - 1];
      title = [last].concat(title.slice(0, title.length - 1));
    }

    return title.join(separator);
  }

  private _getCurrentDownloadSection(path: string) {
    return downloadMenu(this._permissionService).find((menu) => (path === menu.documentType ? menu.label : null));
  }

  private _getParamKeysToRemove(params: Params): string[] {
    return Object.keys(params).filter((item) => item.match(paramsToExcludeRegEx));
  }

  private _handleActivationEnd(event: ActivationEnd, title: string[]): string[] {
    const routeTitle = event?.snapshot?.routeConfig?.data?.title;
    const params: Params = event?.snapshot?.params;
    const paramKeysToRemove = this._getParamKeysToRemove(params);

    title.push(
      Object.keys(params)?.reduce(
        (newTitle, paramKey) =>
          paramKeysToRemove.includes(paramKey) ? newTitle?.replace(params[paramKey], '') : (newTitle = capitalize(params[paramKey])),
        routeTitle
      )
    );
    title.push(routeTitle);

    return title;
  }

  private _handleAppsWithSubnavs(title: string[], event: NavigationEnd): string[] {
    const pathSections = event?.urlAfterRedirects?.split('/');
    const currentSectionPath = pathSections[pathSections?.length - 1];
    let currentSection: string;

    if (event.url.includes('docs')) {
      currentSection = ENVIRONMENT.docsUrl[currentSectionPath]?.displayName;
    }

    if (this._isDownloadSection(event)) {
      currentSection = this._getCurrentDownloadSection(currentSectionPath)?.label;
    }

    return currentSection ? this._updateFirstArrayElement(title, currentSection) : title;
  }

  private _handleNavigationEnd(event: NavigationEnd, title: string[]): void {
    const recentActions: RecentActions[] = this.recentActions;
    const urlWithoutQueryString = this._removeQueryParams(event.url);
    const urlWithoutNew = this._removeNewFromPath(urlWithoutQueryString);
    const separator = ' -> ';

    title = this._handleAppsWithSubnavs(title, event);
    if (title.length > 1) {
      title = title.slice(0, title.length - 1);
    }

    const fullTitle = this._formatFullTitle(title, separator, event);
    const redirectFilteredRecentActions = recentActions.filter((action) => !urlWithoutNew.includes(action.url));

    if (this._canAddUrl(redirectFilteredRecentActions, urlWithoutNew)) {
      this._setRecentActions(fullTitle, urlWithoutNew, redirectFilteredRecentActions);
    }
  }

  private _isDownloadSection(event: NavigationEnd): boolean {
    return event.url.includes('download') && event.urlAfterRedirects !== '/download';
  }

  private _isUniqueAction(recentActions: RecentActions[], url: string): boolean {
    return recentActions.filter((action) => action.url === url).length === 0;
  }

  private _removeLastArrayElement(arr: string[]): string[] {
    return arr.slice(0, arr.length - 1);
  }

  private _removeNewFromPath(url: string): string {
    return url.indexOf('new') !== -1 ? url.substring(0, url.indexOf('/new')) : url;
  }

  private _removeQueryParams(url: string): string {
    // split the url on the ? or the encoded ? (%3F)
    return url.split('?')[0] || url.split('%3F')[0];
  }

  private _setRecentActions(title: string, url: string, recentActions: RecentActions[]): void {
    const updatedRecentActions = recentActions;
    updatedRecentActions.unshift({ copy: title, url });
    this.localStore.setItem(RECENT_ACTIONS, updatedRecentActions.slice(0, 5));
  }

  private _subscribeToRouterEvents() {
    let title: string[] = [];
    let finalTitle: string[] = [];
    this._router.events.pipe(bufferTime(30000)).subscribe((events) => {
      // there is no title on the NavigationEnd event, so capture the title from ActivationEnd
      events.map((event) => {
        if (event instanceof ActivationEnd) {
          title = this._handleActivationEnd(event, title);
          finalTitle = this._filterTitle(title);
        }

        if (event instanceof NavigationEnd) {
          this._handleNavigationEnd(event, finalTitle);
          title = [];
        }
      });
    });
  }

  private _updateFirstArrayElement(titleArr: string[], firstElement: string) {
    const revisedTitle = titleArr.slice(1);
    revisedTitle.unshift(firstElement);

    return revisedTitle;
  }
}

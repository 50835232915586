import { DocsService } from '@shared/services';
import { createSortFn } from '@shared/utilities/sort';
import { NavItem } from 'app/+nav/nav-item.interface';
import { ENVIRONMENT } from 'environment';

export const docsMenu = (docsService: DocsService): NavItem[] =>
  Object.keys(ENVIRONMENT.docsUrl)
    .map((serviceName) => ({
      label: ENVIRONMENT.docsUrl[serviceName].displayName,
      state: `/docs/${serviceName}`,
      icon: serviceName.slice(0, 3).toUpperCase(),
      canRead: docsService.canReadDocumentation(serviceName),
    }))
    .sort(createSortFn((i) => i.label.toLowerCase()));

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { isDate } from 'lodash';
import { take } from 'rxjs/operators';

import { MaintenanceDialogData } from './maintenance-dialog-data.interface';

const dateFormat = 'EEEE, MMMM d, y, h:mm a z';

@Component({
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  public dateFormat = dateFormat;
  public startDate: Date;
  public endDate: Date;
  public isDialog = false;

  constructor(private _route: ActivatedRoute, @Optional() @Inject(MAT_DIALOG_DATA) private _data: MaintenanceDialogData) {}

  public ngOnInit(): void {
    if (this._data) {
      this.isDialog = true;
      this._setDates(this._data.startDate, this._data.endDate);
    } else {
      this._route.queryParamMap.pipe(take(1)).subscribe((queryParams) => this._setDates(queryParams.get('start'), queryParams.get('end')));
    }
  }

  public get hasDates(): boolean {
    return Boolean(this.startDate && this.endDate);
  }

  private _setDates(start: Date | string, end: Date | string): void {
    const startDate = start ? new Date(start) : undefined;
    const endDate = end ? new Date(end) : undefined;

    if (isDate(startDate) && isDate(endDate)) {
      this.startDate = startDate;
      this.endDate = endDate;
    }
  }
}

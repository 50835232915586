import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '@shared/constants';
import { DocumentService, YearlossService } from '@shared/services';
import { downloadBlob } from '@shared/utilities/blob';
import { arrayToCsv } from '@shared/utilities/file';
import { take } from 'rxjs/operators';

import { HelpMenuService } from './help-menu.service';
import { HelpMenuItem } from './help-menu-item.interface';

const MDOC = 'MDOC';
const YLT = 'YLT';

@Component({
  selector: 'mit-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
})
export class HelpMenuComponent {
  @Input()
  set helpMenuId(helpMenuId: string) {
    this._helpMenuItems = [];
    this._buildHelpMenu(helpMenuId);
  }

  private _helpMenuItems: HelpMenuItem[] = [];

  constructor(
    private _router: Router,
    private _helpMenuService: HelpMenuService,
    private _documentService: DocumentService,
    private _yearlossService: YearlossService
  ) {}

  public get helpMenuItems(): HelpMenuItem[] {
    return this._helpMenuItems;
  }

  public goTo(item: HelpMenuItem): void {
    if (item.stateUrl) {
      this._router.navigateByUrl(item.stateUrl);
    } else if (item.redirectUrl) {
      window.open(item.redirectUrl, '_blank');
    } else if (item.headers) {
      this._downloadHeadersFile(item.headers, item.label);
    } else {
      this._documentService.byTypeAndName(MDOC, item.document).then((doc) =>
        this._documentService
          .getSignedLink(doc.id)
          .pipe(take(1))
          .subscribe((res) => window.open(res.url))
      );
    }
  }

  private _buildHelpMenu(helpMenuId: string): void {
    this._helpMenuItems = helpMenuId && this._helpMenuService.helpMenu(helpMenuId) ? this._helpMenuService.helpMenu(helpMenuId) : [];
  }

  private _downloadHeadersFile(type: string, fileName: string): void {
    if (type === YLT) {
      this._yearlossService.getYearlossHeaders().subscribe(({ headers }) => {
        const csvContent = arrayToCsv([], headers);
        const blob = new Blob([csvContent], { type: CONSTANTS.csvType });
        downloadBlob(blob, fileName, 'csv');
      });
    }
  }
}

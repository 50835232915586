import { Injectable } from '@angular/core';
import { DocsService, PermissionService } from '@shared/services';
import { GENERAL_DOCUMENT } from '@shared/services/permission/access-zones.constant';
import { ENVIRONMENT } from 'environment';

import { HelpMenuItem } from './help-menu-item.interface';

const eltgenerator = 'eltgenerator';
const ciab = 'ciab';
const contract = 'contract';
const YLT = 'YLT';

@Injectable()
export class HelpMenuService {
  private _helpMenu: { [key: string]: HelpMenuItem[] } = {
    eptoelt: [
      {
        label: 'FAQ',
        document: 'EP to ELT FAQ',
        canRead: this._canRead(GENERAL_DOCUMENT),
      },
      {
        label: 'Methodology',
        document: 'EP to ELT Methodology',
        canRead: this._canRead(GENERAL_DOCUMENT),
      },
      {
        label: 'Sample Input',
        document: 'EP to ELT Example Input',
        canRead: this._canRead(GENERAL_DOCUMENT),
      },
      {
        label: 'API Guide',
        document: 'Miu Insights API Guide',
        canRead: this._canRead(GENERAL_DOCUMENT),
      },
      {
        label: 'API Docs',
        stateUrl: this._docsService.getUrl(eltgenerator),
        canRead: this._docsService.canReadDocumentation(eltgenerator),
      },
    ],
    ciab: [
      {
        label: 'FAQ',
        document: 'CIAB FAQ',
        canRead: this._canRead(GENERAL_DOCUMENT),
      },
      {
        label: 'Methodology',
        document: 'CIAB Methodology',
        canRead: this._canRead(GENERAL_DOCUMENT),
      },
      {
        label: 'API Guide',
        document: 'Miu Insights API Guide',
        canRead: this._canRead(GENERAL_DOCUMENT),
      },
      {
        label: 'API Docs',
        stateUrl: this._docsService.getUrl(ciab),
        canRead: this._docsService.canReadDocumentation(ciab),
      },
    ],
    contract: [
      {
        label: 'FAQ',
        document: 'Contracts FAQ',
        canRead: this._canRead(GENERAL_DOCUMENT),
      },
      {
        label: 'Sample Model Input',
        headers: YLT,
        canRead: true,
      },
      {
        label: 'API Guide',
        redirectUrl: ENVIRONMENT.isMiuEnvironment ? 'https://docs.miuinsights.com' : 'https://help.rms.com',
        canRead: this._docsService.canReadDocumentation(contract),
      },
      {
        label: 'API Docs',
        stateUrl: this._docsService.getUrl(contract),
        canRead: this._docsService.canReadDocumentation(contract),
      },
    ],
  };

  constructor(private _permissionService: PermissionService, private _docsService: DocsService) {}

  public helpMenu(group: string): HelpMenuItem[] {
    return this._helpMenu[group].filter((item) => item.canRead);
  }

  private _canRead(accessZone: string): boolean {
    return this._permissionService.getGrants(accessZone).read;
  }
}

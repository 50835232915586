import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { UUID } from '@shared/interfaces/uuid.type';
import { CurrencySet } from '@shared/services/currency/currency-set.interface';
import { Currency } from '@shared/services/currency/currency.interface';
import { ENVIRONMENT } from 'environment';
import { Observable, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ExchangeRate } from './exchange-rate.interface';
import { CurrencySetDetails } from './currency-set-details.interface';
import { APIService } from '../api/api.service';

const currencySetFilter = 'Legacy analysis currency set';
const missingDefaultCurrencyError = 'Error: Please set a default currency set';

@Injectable()
export class CurrencyService {
  constructor(private _apiService: APIService) {}

  public getAll(): Observable<Currency[]> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.currencies);
    return this._apiService.get(url);
  }

  public getCurrencySetCurrencies(currencySetUuid: UUID): Observable<string[]> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.currencySetCurrencies(currencySetUuid));
    return this._apiService.get(url);
  }

  public getAllCurrencySets(options?: { isPublic?: boolean; isDefault?: boolean }): Observable<CurrencySet[]> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.currencySets);
    let params: HttpParams = new HttpParams();

    if (options?.isPublic) {
      params = params.set('isPublic', String(options.isPublic));
    }

    if (options?.isDefault) {
      params = params.set('isDefault', String(options.isDefault));
    }

    return this._apiService
      .get(url, { params })
      .pipe(map((currencySets: CurrencySet[]) => currencySets.filter((currencySet) => currencySet.description !== currencySetFilter)));
  }

  public getCurrencySet(uuid: UUID): Observable<CurrencySetDetails> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.currencySet(uuid));
    return this._apiService.get(url);
  }

  public getDefaultCurrencySet(): Observable<CurrencySetDetails> {
    return this.getAllCurrencySets({ isDefault: true }).pipe(
      mergeMap((currencySets) =>
        currencySets?.[0]?.uuid ? this.getCurrencySet(currencySets[0].uuid) : throwError(missingDefaultCurrencyError)
      )
    );
  }

  public getUsdDefaultExchangeRates(): Observable<Map<string, number>> {
    return this.getDefaultCurrencySet().pipe(
      map((currencySet) =>
        currencySet.exchangeRates.reduce((acc, curr) => {
          if (curr.from === 'USD') {
            acc.set(curr.to, curr.rate);
          }
          return acc;
        }, new Map<string, number>())
      )
    );
  }

  public createCurrencySet(date: string, description: string, exchangeRates: ExchangeRate[]): Observable<{ uuid: UUID }> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.currencySets);
    return this._apiService.post(url, { date, description, exchangeRates });
  }

  public deleteCurrencySet(uuid: UUID): Observable<void> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.currencySet(uuid));
    return this._apiService.delete(url);
  }

  public setCurrencyShareStatus(uuid: UUID, isPublic: boolean): Observable<void> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.setShareStatus(uuid));
    return this._apiService.post(url, { isPublic });
  }

  public setCurrencySetDefault(uuid: UUID): Observable<void> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.setDefault(uuid));
    return this._apiService.post(url, {});
  }

  public getLatestCurrencySet(): Promise<CurrencySetDetails> {
    const url = this._addBaseUrl(CONSTANTS.currencyUrl.latestCurrencySet);
    return this._apiService
      .get(url)
      .toPromise()
      .then((res) => res);
  }

  private _addBaseUrl(path: string): string {
    return `${ENVIRONMENT.baseUrl.currency}${path}`;
  }
}

import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class SearchService {
  public connect(element: any): Observable<any> {
    return fromEvent(element, 'keyup').pipe(debounceTime(500), distinctUntilChanged());
  }
}

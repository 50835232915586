// IMP: replace with comma separated usage after github.com/RMSone/miu-security/issues/320 is done
export const queryParams = (params: { [paramName: string]: string[] }, options?: { excludeStart: boolean }) => {
  let queryParamString = '';

  Object.keys(params).forEach((paramName) => {
    const paramArray = params[paramName];
    queryParamString +=
      Array.isArray(paramArray) && paramArray.length > 0 ? paramArray.reduce((prev, next) => `${prev}&${paramName}=${next}`, '') : '';
  });

  return queryParamString.length > 0 ? `${options?.excludeStart ? '' : '?'}${queryParamString.substring(1)}` : '';
};

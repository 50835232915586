import { UUID } from './interfaces/uuid.type';

export const CONSTANTS = {
  maintenance: {
    startDate: new Date(Date.UTC(2021, 6, 3, 8)),
    endDate: new Date(Date.UTC(2021, 6, 3, 9, 30)),
  },
  csvType: 'text/csv;charset=utf-8;',
  unhandledErrorMsg: `The server encountered an error and could not complete your request.
                          Our team is working hard on fixing the issue`,
  dataVersionMismatch: (userDataVersions: string) => `This app is not supported for the data version(s) you license: [${userDataVersions}]`,
  loginUrl: `login`,
  latestSoftwareVersion: `2024.10.1`,
  useSecureConnection: window.location.protocol === `https:`,
  colors: {
    white: '#FFF',
    rmsBlue50: '#468fff',
    gray15: '#DDDEE1',
    rubyRed50: '#d6224a',
    black: '#121314',
  },
  chartColors: [
    '#E36D7C',
    '#61459F',
    '#67B3CF',
    '#DF6A45',
    '#9E296D',
    '#855EDA',
    '#D9374B',
    '#59BBAF',
    '#2E97BE',
    '#21B6A3',
    '#EBC08C',
    '#D93795',
  ],
  notificationsUrl: {
    websocket: `/notifications`,
    managementTopics: `/management/topics`,
  },
  autoImportUrl: {
    files: `/files/link`,
    importProfile: `/profiles/import`,
  },
  contractsDirectoryUrl: {
    dataVersions: `/dataVersions`,
    contracts: {
      new: `/contracts`,
      currencies: `/currencies/codes`,
      allPrograms: `/contracts/programs`,
      versions: `/contracts/dataVersions`,
      edit: (id: number) => `/contracts/${id}/revisions`,
      all: (status: string) => `/contracts/${status}.json`,
      metaData: (name: string) => `/contracts/metadata/${name}`,
      portfolioExtensibleMetadata: `/portfolios/metadata`,
      programExtensibleMetadata: `/programs/metadata`,
      contractExtensibleMetadata: `/contracts/metadata`,
      single: (id: number, version: string) => `/contracts/${id}/${version}.json`,
      singleOp: (id: number, version: string) => `/contracts/${id}/${version}`,
      assignDoc: (id: number) => `/contracts/${id}/docs`,
      profile: (id: number, version: string) => `/contracts/${id}/versions/${version}/profile`,
      profileSummary: (fileId: string) => `/contracts/profiles/${fileId}`,
      setAccessInfo: (id: number) => `/contracts/${id}/access`,
      deleteContract: (id: number) => `/contracts/${id}`,
    },
    docs: {
      newDoc: `/contractDocs`,
      programDocs: (programId: number) => `/programs/${programId}/docs`,
      contractDocs: (contractId: number, status: boolean) => `/contracts/${contractId}/docs?approved=${status}`,
      approveByProgram: (programId: number, typeCode: string, docName: string) =>
        `/programs/${programId}/docs/type/${typeCode}/${docName}/approve`,
      approveByContract: (contractId: number, typeCode: string, docName: string) =>
        `/contracts/${contractId}/docs/type/${typeCode}/${docName}/approve`,
    },
  },
  docsUrl: {
    names: `/docs/name`,
    newDoc: `/docs`,
    allPermissions: `/docs/type/permissions`,
    uploadUrl: `/files/uploadUrl`,
    rename: (docId: number) => `/docs/${docId}/rename`,
    byProgramUuid: (uuid: UUID) => `/programs/${uuid}/docs`,
    doc: (uuid: UUID) => `/docs/${uuid}`,
    all: (count: string) => `/docs.json?count=${count}`,
    byType: (documentType: string, status: string) => `/docs/type/${documentType}/${status}.json`,
    byTypeAndName: (documentType: string, documentName: string) => `/docs/type/${documentType}/${documentName}`,
    singleOpRevision: (id: number, version: number) =>
      `/docs/${
        id // IMP: Drop endpoint: #3208
      }/revisions/${version}`,
    singleOp: (id: number) => `/docs/${id}`,
    versions: (id: number) => `/docs/${id}/versions.json`,
    updateDoc: (id: number) => `/docs/${id}/revisions`,
    permissions: (documentType: string) => `/docs/type/${documentType}/permissions`,
    delete: (docId: number) => `/docs/${docId}`,
  },
  fileUrl: { signedLink: (docId: number) => `/docs/${docId}/file` },
  securityUrl: {
    login: '/Login/implicit',
    logout: '/Profile/signout',
    refresh: '/login/refresh',
    create: `/v2/oauth2/token`,
    revoke: `/v2/oauth2/revoke`,
    updatePassword: `/users/password`,
    resetPasswordRoute: `/login/reset-password?prt=`,
    forgotPassword: `/users/password/forgot`,
    resetPassword: `/users/password/reset`,
    validateResetToken: `/users/password/reset/validate`,
    miuDataVersions: `/miuDataVersions`,
    miuSoftwareVersions: `/miuSoftwareVersions`,
    permissions: `/permissions`,
    roles: `/roles`,
    organizations: `/organizations`,
    organizationNames: `/organizations/names`,
    users: `/users`,
    userNames: `/users/names`,
    miuDataVersion: (description: string) => `/miuDataVersions/${description}`,
    miuSoftwareVersion: (description: string) => `/miuSoftwareVersions/${description}`,
    permission: (name: string) => `/permissions/${name}`,
    rolesByPermissionName: (name: string) => `/permissions/${name}/roles`,
    role: (id: number) => `/roles/${id}`,
    roleOrganization: (id: number) => `/roles/${id}/organizations`,
    organization: (id: number) => `/organizations/${id}`,
    organizationUsers: (id: number) => `/organizations/${id}/users`,
    organizationUser: (organizationId: number, userId: number) => `/organizations/${organizationId}/users/${userId}`,
    organizationByUuid: (uuid: UUID) => `/organizations/${uuid}`,
    user: (id: number) => `/users/${id}`,
    userByUuid: (uuid: UUID) => `/users/${uuid}`,
  },
  emailUrl: {
    general: `/email`,
    withTemplate: (templateName: string) => `/email/${templateName}`,
  },
  ep2eltUrl: {
    dataVersions: `/dataVersions`,
    upload: `/oep/file`,
    multiCurve: `/oep/multicurve`,
    unmodeled: `/oep/unmodeled`,
    modeled: `/oep/modeled`,
    eltTypes: `/ilc/eltTypes`,
    lookups: `/ilc/lookups`,
    currencies: `/ilc/currencies`,
    countries: `/ilc/countries`,
    hierarchy: (eltTypeCode: string, eltDataVersion: string) => `/ilc/eltTypes/${eltTypeCode}/dataVersions/${eltDataVersion}/hierarchy`,
    ilcEltData: (
      eltTypeCode: string,
      dataVersion: string,
      perilCode: string,
      regionCode: string
    ) => `/ilc/eltTypes/${eltTypeCode}/dataVersions/${dataVersion}/perils/
      ${perilCode}/regions/${regionCode}`,
  },
  exposureLibraryUrl: {
    plt: (uuid: UUID) => `/plts/${uuid}`,
    allPlt: '/plts',
    pltUpdateMetadata: (uuid: UUID) => `/plts/${uuid}/updateMetadata`,
    eltStagingLink: `/elts/staging/link`,
    pltStagingLink: `/plts/staging/link`,
    pltStagingLinkHD: `/plts/hd/staging/link`,
    eltStagingJob: `/elts/staging/job`,
    pltStagingJob: `/plts/staging/job`,
    pltStagingJobHD: `/plts/hd/staging/job`,
    eltStagingJobs: `/elts/staging/jobs`,
    pltStagingJobs: `/plts/staging/jobs`,
    pltStagingJobsHD: `/plts/hd/staging/jobs`,
    eltStagingJobId: (jobId: UUID) => `/elts/staging/job/${jobId}`,
    pltStagingJobId: (jobId: UUID) => `/plts/staging/job/${jobId}`,
    pltStagingJobIdHD: (jobId: UUID) => `/plts/hd/staging/job/${jobId}`,
    eltStagingCopy: (jobId: UUID) => `/elts/staging/job/${jobId}/copy`,
    pltStagingCopy: (jobId: UUID) => `/plts/staging/job/${jobId}/copy`,
    pltHDstagingCopy: (jobId: UUID) => `/plts/hd/staging/job/${jobId}/copy`,
    eltStagingBulkCopy: '/elts/staging/jobs/copy',
    pltStagingBulkCopy: '/plts/staging/jobs/copy',
    pltHDstagingBulkCopy: '/plts/hd/staging/jobs/copy',
    eltData: (uuid: UUID) => `/elts/${uuid}/data`,
    pltData: (uuid: UUID) => `/plts/${uuid}/data`,
    pltHdData: (uuid: UUID) => `/plts/hd/${uuid}/data`,
    shareFactorsPltData: (uuid: UUID) => `/shareFactors/${uuid}/pltData`,
    shareFactorsEltData: (uuid: UUID) => `/shareFactors/${uuid}/eltData`,
    actions: (type: string) => `/references/${type}/actions`,
    errors: (type: string) => `/references/${type}/errors`,
    modelUpdates: `/modelUpdates`,
    bulkUpgradeModelVersion: `/elts/upgrade`,
    elt: (uuid: UUID) => `/elts/${uuid}`,
    allElt: '/elts',
    eltUpdateMetadata: (uuid: UUID) => `/elts/${uuid}/updateMetadata`,
    stagingUpgrade: (uuid: UUID) => `/elts/${uuid}/staging`,
    copyUpgrade: (uuid: UUID) => `/elts/${uuid}/copy`,
    event: (eventId: number) => `/events/${eventId}`,
    events: `/events`,
    exposures: `/exposures`,
    perilRegionByExposures: `/exposures/perilsRegions`,
    allShareFactors: `/shareFactors`,
    shareFactors: (shareFactorsUuid: UUID) => `/shareFactors/${shareFactorsUuid}`,
  },
  contractsUrl: {
    program: (programUuid: UUID) => `/programs/${programUuid}`,
    programRevisions: (programUuid: UUID) => `/programs/${programUuid}/revisions`,
    programRevision: (programRevisionUuid: UUID) => `/programs/revisions/${programRevisionUuid}`,
    programRevisionExposures: (programRevisionUuid: UUID) => `/programs/revisions/${programRevisionUuid}/exposures`,
    programRevisionsLatest: (programUuid: UUID) => `/programs/${programUuid}/revisions/latest`,
    programRevisionFinalize: (programRevisionUuid: UUID) => `/programs/revisions/${programRevisionUuid}/finalize`,
    programRevisionSetPublic: (programRevisionUuid: UUID) => `/programs/revisions/${programRevisionUuid}/setPublic`,
    programRevisionSetName: (programRevisionUuid: UUID) => `/programs/revisions/${programRevisionUuid}/setRevisionName`,
    programs: `/programs`,
    programDocuments: (programUuid: UUID) => `/programs/${programUuid}/documents`,
    programUnlinkDocuments: (programUuid: UUID) => `/programs/${programUuid}/unlinkDocuments`,
    templates: `/templates`,
    template: (uuid: UUID) => `/templates/${uuid}`,
    contracts: `/contracts`,
    contract: (contractUuid: UUID) => `/contracts/${contractUuid}`,
    metadata: `/contracts/metadataValues`,
    portfolios: `/portfolios`,
    portfolio: (portfolioUuid: UUID) => `/portfolios/${portfolioUuid}`,
    portfolioStagingLink: `/portfolios/staging/link`,
    portfolioRevisionDetails: (portfolioRevisionUuid: UUID) => `/portfolios/revisions/${portfolioRevisionUuid}`,
    portfolioRevisionsExposures: (portfolioRevisionUuid: UUID) => `/portfolios/revisions/${portfolioRevisionUuid}/exposures`,
    portfolioRevisionSetName: (portfolioRevisionUuid: UUID) => `/portfolios/revisions/${portfolioRevisionUuid}/setRevisionName`,
    portfolioRevisionsLatest: (portfolioUuid: UUID) => `/portfolios/${portfolioUuid}/revisions/latest`,
    portfolioRevisions: (portfolioUuid: UUID) => `/portfolios/${portfolioUuid}/revisions`,
    portfolioValidateContractsCsv: `/portfolios/validatePortfolioContractsCsv/`,
    programsMetadataProgramDropdownValues: `/programs/metadataDropDownValues/program`,
    programsMetadataRiskDataDropdownValues: `/programs/metadataDropDownValues/riskData`,
  },
  analysisUrl: {
    simulation: '/analyses/metrics',
    deterministic: '/analyses/deterministic',
    websocket: '/analyses/notifications',
    analyses: '/analyses',
    analysesV2: '/analyses/findAnalysisV2',
    paginated: '/analyses/paginated',
    paginatedV2: '/analyses/paginatedV2',
    validate: '/analyses/validate',
    analysis: (analysisUuid: UUID) => `/analyses/${analysisUuid}`,
    queuedAnalyses: '/queue/analyses',
    queuedAnalysis: (analysisUuid: UUID) => `/queue/analyses/${analysisUuid}`,
    metrics: (analysisUuid: UUID) => `/analyses/${analysisUuid}/metrics`,
    deterministicMetrics: (analysisUuid: UUID) => `/analyses/${analysisUuid}/deterministic`,
    epCurves: (analysisUuid: UUID) => `/analyses/${analysisUuid}/epCurves`,
    perilRegionEpCurves: (analysisUuid: UUID) => `/analyses/${analysisUuid}/epCurves/perilRegion`,
    clusterState: '/cluster/state',
    cluster: (clusterId: UUID) => `/cluster/${clusterId}`,
    outputs: (analysisUuid: UUID) => `/analyses/${analysisUuid}/outputs`,
    contractCorrelation: (analysisUuid: UUID) => `/analyses/${analysisUuid}/contractCorrelation`,
    cumulativeELA: (analysisUuid: UUID) => `/analyses/${analysisUuid}/cumulativeEla`,
    participationRunoff: (analysisUuid: UUID) => `/analyses/${analysisUuid}/participationRunoff`,
    largestEvents: (analysisUuid: UUID) => `/analyses/${analysisUuid}/largestEvents`,
    cpltData: (analysisUuid: UUID) => `/analyses/${analysisUuid}/cplts/data`,
    cpltDataFile: (analysisUuid: UUID) => `/analyses/${analysisUuid}/data/file`,
    ipltData: (analysisUuid: UUID) => `/analyses/${analysisUuid}/iplts/data`,
  },
  driversOfLossUrl: {
    driversOfLoss: `/driversOfLoss/contract/data`,
  },
  yearlossLibraryUrl: {
    dataVersions: `/dataVersions`,
    yearlosses: `/yearlosses`,
    yearloss: (yltUuid: UUID) => `/yearlosses/${yltUuid}`,
    perilRegions: `/yearlosses/perilRegions`,
    riskProfiles: (dataVersionUuid: UUID) => `/dataVersions/${dataVersionUuid}/yearlosses`,
    yearlossSets: `/yearlossSets`,
    yearlossSet: (yltSetUuid: UUID) => `/yearlossSets/${yltSetUuid}`,
    yearlossStagingHeaders: `/yearlosses/staging/headers`,
    yearlossStagingJob: (dataVersionUuid: UUID) => `/dataVersions/${dataVersionUuid}/yearlosses/staging/job`,
    yearlossWithYltUuidStagingJob: (dataVersionUuid: UUID, yltUuid: UUID) =>
      `/dataVersions/${dataVersionUuid}/yearlosses/${yltUuid}/staging/job`,
    yearlossWithYltUuidStagingGenerateJob: (dataVersionUuid: UUID, yltUuid: UUID) =>
      `/dataVersions/${dataVersionUuid}/yearlosses/${yltUuid}/staging/generate/job`,
    yearlossStagingJobId: (stagingJobUuid: UUID) => `/yearlosses/staging/job/${stagingJobUuid}`,
    yearlossStagingGenerateJobId: (stagingJobUuid: UUID) => `/yearlosses/staging/generate/job/${stagingJobUuid}`,
    yearlossStagingLink: `/yearlosses/staging/link`,
    yearlossGenerateStagingJob: (dataVersionUuid: UUID) => `/dataVersions/${dataVersionUuid}/yearlosses/staging/generate/job`,
    yearlossSetPublic: (yltUuid: UUID) => `/yearlosses/${yltUuid}/setPublic`,
  },
  ciabUrl: {
    dataVersions: `/dataVersions`,
    models: `/ciab/models`,
    elt: `/ciab/elt`,
    oep: `/ciab/oep`,
    file: `/ciab/file`,
    historicals: (regionCode: string) => `/historical/tracks/${regionCode}`,
    historicalIntersect: (regionCode: string) => `/historical/tracks/${regionCode}/intersect`,
    boundary: (dataVersion: string, regionCode: string, perilCode: string) => `/ciab/boundaries/${dataVersion}/${regionCode}/${perilCode}`,
  },
  currencyUrl: {
    currencies: `/currencies`,
    currencySet: (uuid: UUID) => `/currencySets/${uuid}`,
    currencySetCurrencies: (uuid: UUID) => `/currencySets/${uuid}/currencies`,
    currencySets: `/currencySets`,
    setShareStatus: (uuid: UUID) => `/currencySets/${uuid}/setPublic`,
    setDefault: (uuid: UUID) => `/currencySets/${uuid}/setDefault`,
    latestCurrencySet: `/currencySets/latest`,
  },
  referenceUrl: {
    dataVersions: `/dataVersions`,
    dataVersion: (uuid: UUID) => `/dataVersions/${uuid}`,
    models: `/models`,
    perils: `/perils`,
    peril: (perilUuid: UUID) => `/perils/${perilUuid}`,
    regions: `/regions`,
    region: (regionUuid: UUID) => `/regions/${regionUuid}`,
    rateViews: `/rateViews`,
    rateView: (rateViewUuid: UUID) => `/rateViews/${rateViewUuid}`,
    lobs: `/lobs`,
    resolutions: `/subRegionResolutions`,
    hierarchies: `/hierarchies`,
  },
  filesUrl: {
    documentsTypes: `/documents/types`,
    documentsTags: `/documents/tags`,
  },
};

import { PermissionService } from '@shared/services';
import { GENERAL_DOCUMENT, MIU_RELEASE, PRICING_DOCUMENT } from '@shared/services/permission/access-zones.constant';

export const downloadMenu = (permissionService: PermissionService) => [
  {
    label: 'Miu Pricing Reports',
    documentType: 'MPRC',
    state: '/download/MPRC',
    approvedLimit: 12,
    svgIcon: 'pricing_report',
    canRead: permissionService.getGrants(PRICING_DOCUMENT).read,
    canCreate: permissionService.getGrants(PRICING_DOCUMENT).create,
    canPublish: permissionService.getGrants(PRICING_DOCUMENT).publish,
  },
  {
    label: 'Risk Profiles',
    documentType: 'RP',
    state: '/download/RP',
    svgIcon: 'risk_profile',
    canRead: permissionService.getGrants(MIU_RELEASE).read,
    canCreate: permissionService.getGrants(MIU_RELEASE).create,
    canPublish: permissionService.getGrants(MIU_RELEASE).publish,
    showUploadTags: true,
  },
  {
    label: 'Documents',
    documentType: 'MDOC',
    state: '/download/MDOC',
    svgIcon: 'document',
    canRead: permissionService.getGrants(PRICING_DOCUMENT).read || permissionService.getGrants(GENERAL_DOCUMENT).read,
    canCreate: permissionService.getGrants(PRICING_DOCUMENT).create || permissionService.getGrants(GENERAL_DOCUMENT).create,
    canPublish: permissionService.getGrants(PRICING_DOCUMENT).publish || permissionService.getGrants(GENERAL_DOCUMENT).publish,
    showUploadTags: true,
  },
  {
    label: 'Miu Releases',
    documentType: 'MRD',
    state: '/download/MRD',
    svgIcon: 'miu_release',
    canRead: permissionService.getGrants(MIU_RELEASE).read,
    canCreate: permissionService.getGrants(MIU_RELEASE).create,
    canPublish: permissionService.getGrants(MIU_RELEASE).publish,
    showUploadTags: true,
    canSelectMultipleUploadTags: true,
  },
  {
    label: 'Calculation Templates',
    documentType: 'CALCT',
    state: '/download/CALCT',
    svgIcon: 'calculation_templates',
    canRead: permissionService.getGrants(MIU_RELEASE).read,
    canCreate: permissionService.getGrants(MIU_RELEASE).create,
    canPublish: permissionService.getGrants(MIU_RELEASE).publish,
  },
  {
    label: 'Custom Analytics',
    documentType: 'MCA',
    state: '/download/MCA',
    svgIcon: 'custom_analytics',
    canRead: permissionService.getGrants(MIU_RELEASE).read,
    canCreate: permissionService.getGrants(MIU_RELEASE).create,
    canPublish: permissionService.getGrants(MIU_RELEASE).publish,
  },
];

<div class="password-change content-wrapper">
  <form name="changePasswordForm" (ngSubmit)="onChangePassword()" #changePasswordForm="ngForm">
    <mat-form-field>
      <input
        matInput
        type="password"
        class="ext-current-password"
        placeholder="Current Password"
        autofocus
        ignoreLimitedChars
        name="currentPassword"
        [(ngModel)]="state.details.currentPassword"
        required
      />
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        type="password"
        class="ext-new-password"
        placeholder="New Password"
        autofocus
        ignoreLimitedChars
        name="newPassword"
        [(ngModel)]="state.details.newPassword"
        required
      />
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        type="password"
        class="ext-confirm-password"
        placeholder="Confirm New Password"
        ignoreLimitedChars
        name="newConfirmPassword"
        [(ngModel)]="state.details.newConfirmPassword"
        required
      />
    </mat-form-field>

    <div class="buttons radius-button-column">
      <button mat-flat-button type="submit" class="radius radius-primary ext-change-password" [disabled]="changePasswordForm.invalid">
        Change Password
      </button>

      <button mat-flat-button type="reset" (click)="onClickCancel()" class="radius">Cancel</button>
    </div>
  </form>
</div>

// IMP: Refactor into utilities: #4306
import { StorageLike } from '@shared/storage/storage-like.interface';
import { cloneDeep } from 'lodash';

import { KeyValueStore } from './key-value-store.class';

export class LocalStore<T> extends KeyValueStore<T> {
  private _storage: StorageLike<T>;
  private get _isRoot(): boolean {
    return this._storage instanceof Storage;
  }

  constructor(storage: StorageLike<T> | Storage, key: string) {
    super(key);
    this._storage = storage;

    if (this._isRoot) {
      this.values = this._getRoot();
      this._writeRoot();
    } else {
      this.values = this._getSelf() as T;
      this._updateParent();
    }
  }

  public getItem(key: string): any {
    if (this._isRoot) {
      return this._getRoot()[key];
    } else {
      return this._getSelf()[key];
    }
  }

  public setItem(key: string, values: any): void {
    super.updateValues(key, values);
    if (this._isRoot) {
      this._writeRoot();
    } else {
      this._updateParent();
    }
  }

  public removeItem(key: string): void {
    super.removeItem(key);
    if (this._isRoot) {
      this._writeRoot();
    } else {
      this._updateParent();
    }
  }

  public removeRoot(): void {
    if (this._isRoot) {
      this._storage.removeItem(this.key);
    } else {
      throw new Error('Attempting to remove non-root store');
    }
  }

  public clone(newKey: string): LocalStore<T> {
    const clone = new LocalStore<T>(this._storage, newKey);
    clone.values = cloneDeep(this.values);

    if (this._isRoot) {
      clone._writeRoot();
    } else {
      clone._updateParent();
    }
    return clone;
  }

  private _getSelf(): T | string {
    return this._storage.getItem(this.key) || ({} as T);
  }

  private _getRoot(): T {
    return JSON.parse(this._storage.getItem(this.key) as string) || {};
  }

  private _updateParent(): void {
    this._storage.setItem(this.key, this.values);
  }

  private _writeRoot(): void {
    try {
      this._storage.setItem(this.key, JSON.stringify(this.values));
    } catch {
      try {
        this._storage.removeItem(this.key);
        this._writeRoot();
      } catch {
        console.warn('localStorage is full');
      }
      // TODO: #3537 jimmy UI is unusable by the time this is full, so should do something before.
    }
  }
}

export const downloadBlob = (blob: Blob, fileName = 'text', extension = 'txt'): void => {
  if (blob) {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = `${fileName}.${extension}`;
    a.target = '_self';
    const event = new MouseEvent('click', { view: window, bubbles: true, cancelable: false });
    a.dispatchEvent(event);
  }
};

export const downloadFile = (url: string): void => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_self';
  const event = new MouseEvent('click', { view: window, bubbles: true, cancelable: false });
  a.dispatchEvent(event);
};

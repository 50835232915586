import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { GlobalsModule } from '@shared/globals/globals.module';
import { DocsModule } from '@shared/services/docs';
import { PermissionModule } from '@shared/services/permission';
import { TrackingModule } from '@shared/services/tracking';

import { NavService } from './nav.service';
import { NavComponent } from './nav.component';
import { NavListItemComponent } from './nav-list-item/nav-list-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GlobalsModule,
    PermissionModule,
    TrackingModule,
    DocsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  declarations: [NavComponent, NavListItemComponent],
  exports: [NavComponent, NavListItemComponent],
  providers: [NavService],
})
export class NavModule {}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { Profile } from '@shared/services/session/profile.interface';
import { ENVIRONMENT } from 'environment';

import { ValidateTokenRes } from './validate-token-res.interface';
import { AuthService } from '../auth/auth.service';
import { AuthSmlService } from '../auth-sml/auth-sml.service';
import { SmlProfile } from '../auth-sml/auth-sml.interface';

@Injectable()
export class SecurityService {
  constructor(private _http: HttpClient, private _authService: AuthService, private _authSmlService: AuthSmlService) {}

  public getProfile(): Promise<Profile | SmlProfile> {
    const bearerAuthToken = ENVIRONMENT.isMiuEnvironment ? `Bearer ${this._authService.accessToken}` : this._authSmlService.bearerAuthToken;
    const headers = new HttpHeaders().set('Authorization', bearerAuthToken);
    const options = { headers };
    const url = ENVIRONMENT.isMiuEnvironment
      ? `${ENVIRONMENT.baseUrl.cms}${CONSTANTS.securityUrl.create}`
      : `${ENVIRONMENT.baseUrl.smlv2}/${this._authSmlService.username}`;

    return this._http
      .get(url, options)
      .toPromise()
      .then((res: Profile | SmlProfile) => res);
  }

  public forgotPassword(email: string): Promise<any> {
    const redirectLink = `${ENVIRONMENT.baseUrl.root}${CONSTANTS.securityUrl.resetPasswordRoute}`;
    const postData = { email, redirectLink };
    const url = `${ENVIRONMENT.baseUrl.cms}${CONSTANTS.securityUrl.forgotPassword}`;
    return this._http
      .post(url, postData)
      .toPromise()
      .then(
        (res) => res,
        (error) => error
      );
  }

  public resetPassword(token: string, newPassword: string): Promise<any> {
    const postData = { token, newPassword };
    const url = `${ENVIRONMENT.baseUrl.cms}${CONSTANTS.securityUrl.resetPassword}`;
    return this._http
      .post(url, postData)
      .toPromise()
      .then((res) => res);
  }

  public updatePassword(oldPassword: string, newPassword: string): Promise<any> {
    const token = this._authService.accessToken;
    const headers = new HttpHeaders().set('Authorization', this._bearerToken(token));
    const options = { headers };
    const postData = { oldPassword, newPassword };
    const url = `${ENVIRONMENT.baseUrl.cms}${CONSTANTS.securityUrl.updatePassword}`;
    return this._http
      .post(url, postData, options)
      .toPromise()
      .then((res) => res);
  }

  public validateResetToken(token: string): Promise<ValidateTokenRes> {
    const postData = { token };
    const url = `${ENVIRONMENT.baseUrl.cms}${CONSTANTS.securityUrl.validateResetToken}`;
    return this._http
      .post(url, postData)
      .toPromise()
      .then((res: ValidateTokenRes) => res);
  }

  public revokeToken(): void {
    const accessToken = this._authService.accessToken;
    const refreshToken = this._authService.refreshToken;
    const headers = new HttpHeaders().set('Authorization', this._bearerToken(accessToken));
    const url = `${ENVIRONMENT.baseUrl.cms}${CONSTANTS.securityUrl.revoke}`;
    const form = new FormData();
    form.set('token', refreshToken);

    if (accessToken && refreshToken) {
      this._http.post(url, form, { headers, responseType: 'text' }).toPromise();
    }
  }

  private _bearerToken(token: string): string {
    return `Bearer ${token}`;
  }
}

const errorMsg = `Error reading file`;
export const readLines = (file: File): Promise<string[]> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const matchOneLineRe = /.+/g;
      const text = String(reader.result);
      const firstLineMatch = matchOneLineRe.exec(text);
      const lines = [];

      if (firstLineMatch) {
        lines.push(firstLineMatch[0]);
        let readMore = true;

        while (readMore) {
          const nextLine = matchOneLineRe.exec(text);
          if (nextLine) {
            lines.push(nextLine[0]);
          }
          readMore = Boolean(nextLine) || false;
        }

        resolve(lines);
      } else {
        resolve(lines);
      }
    };

    reader.onerror = () => {
      reject(errorMsg);
    };

    if (file) {
      reader.readAsText(file);
    } else {
      reject(errorMsg);
    }
  });
};

import { Environment } from './environment.interface';

export const ENVIRONMENT: Environment = {
  production: true,
  envName: 'production',
  isMiuEnvironment: true,
  isWebSocketNotificationEnabled: false,

  baseUrl: {
    notification: 'https://notification.miuinsights.com/v1',
    autoImport: 'https://autoimport.miuinsights.com/v1',
    document: 'https://document.miuinsights.com',
    cms: 'https://cms.miuinsights.com',
    currency: 'https://currency.miuinsights.com/v3',
    ep2elt: 'https://eltgen.miuinsights.com',
    ciab: 'https://ciab.miuinsights.com',
    contract: 'https://contract.miuinsights.com/v2',
    analysis: 'https://analysis.miuinsights.com/v3',
    ylt: 'https://yltlibrary.miuinsights.com/v2',
    exposureLibrary: 'https://exposurelibrary.miuinsights.com/v1',
    reference: 'https://reference.miuinsights.com/v1',
    files: 'https://files.miuinsights.com/v1',
    root: 'https://www.miuinsights.com',
  },

  docsUrl: {
    eltgenerator: {
      url: 'https://eltgen.miuinsights.com/api-docs/swagger.json',
      displayName: 'ELT Generator',
    },
    security: {
      url: 'https://cms.miuinsights.com/api-docs/swagger.json',
      displayName: 'Security',
    },
    contract: {
      url: 'https://contract.miuinsights.com/v2/api-docs/swagger.json',
      displayName: 'Contract V2',
    },
    exposurelibrary: {
      url: 'https://exposurelibrary.miuinsights.com/v1/api-docs/swagger.json',
      displayName: 'Exposure Library V1',
    },
    currency: {
      url: 'https://currency.miuinsights.com/v3/api-docs/swagger.json',
      displayName: 'Currency V3',
    },
    yltlibrary: {
      url: 'https://yltlibrary.miuinsights.com/v2/api-docs/swagger.json',
      displayName: 'YLT Library V2',
    },
    analysis: {
      url: 'https://analysis.miuinsights.com/v3/api-docs/swagger.json',
      displayName: 'Analysis V3',
    },
    ciab: {
      url: 'https://ciab.miuinsights.com/api-docs/swagger.json',
      displayName: 'CIAB',
    },
    notifications: {
      url: 'https://notification.miuinsights.com/v1/api-docs/swagger.json',
      displayName: 'Notifications V1',
    },
    reference: {
      url: 'https://reference.miuinsights.com/v1/api-docs/swagger.json',
      displayName: 'Reference V1',
    },
    files: {
      url: 'https://files.miuinsights.com/v1/api-docs/swagger.json',
      displayName: 'Files V1',
    },
    autoimport: {
      url: 'https://autoimport.miuinsights.com/api-docs/swagger.json',
      displayName: 'Auto Import',
    },
  },

  tracking: {
    mixpanelAccessToken: '68beb5e607a253fb09ec31256acfbfa5 ',
    track: false,
  },

  mapBoxAccessToken: 'pk.eyJ1IjoicXVhbnR1bXNyZSIsImEiOiJja2htNnExbXUwbWRzMzVvOXNlZ3YzcHVtIn0.QiUFem0sAONqiuHqaT9zWQ',
};

<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<div mat-dialog-content>
  <h5>{{ data.message }}</h5>

  <ul *ngIf="data.lines">
    <li *ngFor="let line of data.lines">{{ line }}</li>
  </ul>
</div>

<div mat-dialog-actions>
  <button mat-flat-button (click)="negative()" tabindex="1" class="radius radius-plain ext-cancel">Cancel</button>

  <button mat-flat-button (click)="affirmative()" tabindex="2" class="radius radius-primary ext-ok">
    {{ data.actions?.affirmative }}
  </button>
</div>

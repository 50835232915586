import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { capitalize } from '@shared/utilities/case';

@Component({
  templateUrl: 'delete-dialog.component.html',
  styleUrls: ['delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      info?: string;
      msg?: string;
      neverShow?: boolean;
      canBeHidden?: boolean;
      lines?: string[];
      action: string;
    }
  ) {
    this.data.msg = this.data.msg || `Are you sure you want to delete this ${this.data.type} ?`;
  }

  public capitalize(input: string): string {
    return capitalize(input);
  }

  public affirmative(): void {
    this._dialogRef.close(this.data);
  }

  public negative(): void {
    this._dialogRef.close();
  }
}

import { DocsService } from '@shared/services';
import { NavItem } from 'app/+nav/nav-item.interface';
import { ENVIRONMENT } from 'environment';

export const appDocsMenu = (docsService: DocsService): NavItem[] => {
  const _canReadSomeDocumentation = (): boolean =>
    Object.keys(ENVIRONMENT.docsUrl).some((serviceName) => docsService.canReadDocumentation(serviceName));

  return [
    {
      description: 'All our services in one place.',
      isOther: true,
      label: 'API Explorer',
      svgIcon: 'api_explorer',
      state: 'docs',
      canRead: _canReadSomeDocumentation(),
    },
  ];
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { GlobalsModule } from '@shared/globals/globals.module';

import { LoginChangePasswordComponent } from './login-change-password.component';
import { LoginChangePasswordRoutingModule } from './login-change-password-routing.module';

@NgModule({
  imports: [GlobalsModule, CommonModule, FormsModule, MatButtonModule, MatInputModule, LoginChangePasswordRoutingModule],
  declarations: [LoginChangePasswordComponent],
  exports: [LoginChangePasswordComponent],
  providers: [],
})
export class LoginChangePasswordModule {}

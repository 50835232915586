<div class="ie-message" *ngIf="browserIsIE()">
  <h3>
    This site is best viewed on a modern evergreen browser like Chrome, Firefox, Edge, or Safari. Some features may not work as designed if
    viewed in an unsupported browser i.e. Internet Explorer.
  </h3>
  <h5>
    If you have any difficulties upgrading to one of the supported browsers, please contact
    <a href="mailto:RMS_miuinsights@moodys.com">RMS_miuinsights@moodys.com</a>.
  </h5>
</div>

<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mit-nav *ngIf="canShowShell()" [sidenav]="sidenav"></mit-nav>
  </mat-sidenav>

  <mat-sidenav-content id="page-wrapper" [class.no-margin]="!canShowShell()">
    <ul id="subnav" *ngIf="canShowShell()">
      <mit-nav-list-item *ngFor="let item of subnav" [menuItem]="item" class="subnav ext-subnav-item"></mit-nav-list-item>
    </ul>

    <div id="content-wrapper">
      <div id="header-wrapper" [class.hidden]="!canShowShell()">
        <button mat-icon-button class="hamburger ext-menu" (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>

        <mit-header [breadcrumb]="navBreadcrumb"></mit-header>
      </div>

      <div *ngIf="canRenderApplication()" id="body-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

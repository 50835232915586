import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { UUID } from '@shared/interfaces/uuid.type';
import { handleApiParams, queryParams } from '@shared/utilities/params';
import { ENVIRONMENT } from 'environment';
import { isArray, isUndefined } from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { APIService } from '../api/api.service';
import { CATEGORIES } from '../tracking/tracking.const';
import { TrackingService } from '../tracking/tracking.service';
import { ReferenceBase } from './reference-base.interface';
import { ReferenceDataVersion } from './reference-data-version.interface';
import { ReferenceHierarchy } from './reference-hierarchy.interface';
import { ReferenceLob } from './reference-lob.interface';
import { ReferenceModel } from './reference-model.interface';
import { ReferencePeril } from './reference-peril.interface';
import { ReferenceRateView } from './reference-rate-view.interface';
import { ReferenceRegion } from './reference-region.interface';
import { ReferenceResolution } from './reference-resolution.interface';
import { ReferenceSubRegion } from './reference-sub-region.interface';

@Injectable()
export class ReferenceService {
  constructor(private _apiService: APIService, private _trackingService: TrackingService) {}

  public referenceAsMap<T extends ReferenceBase>(reference$: Observable<T[]>): Observable<Map<UUID, T>> {
    return reference$.pipe(map((items) => new Map(items.map((item) => [item.uuid, item]))));
  }

  public dataVersionsAsMap(reference$: Observable<ReferenceDataVersion[]>): Observable<Map<string, ReferenceDataVersion>> {
    return reference$.pipe(map((items) => new Map(items.map((item) => [item.dataVersionId, item]))));
  }

  public resolveDataVersionUuid$(dataVersionId: string): Observable<UUID | undefined> {
    const dataVersionsMap$ = this.dataVersionsAsMap(this.dataVersions());
    return dataVersionsMap$.pipe(map((dataVersionMap) => dataVersionMap.get(dataVersionId)?.uuid));
  }

  public hierarchies(options: {
    dataVersionUuid?: UUID;
    perilUuid?: UUID;
    regionUuid?: UUID;
    isIlc?: boolean;
    modelUuid?: UUID[];
  }): Observable<ReferenceHierarchy[]> {
    let url = this._addBaseUrl(CONSTANTS.referenceUrl.hierarchies);
    let params = new HttpParams();

    const addParams = (key: string) => {
      if (key && !isUndefined(options[key])) {
        if (isArray(options[key])) {
          url = url + queryParams({ [key]: options[key] });
        } else {
          params = params.set(key, String(options[key]));
        }
      }
    };

    if (options) {
      Object.keys(options).forEach(addParams);
    }
    return this._apiService.get(url, { params });
  }

  public models(): Observable<ReferenceModel[]> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.models);
    return this._apiService.get(url);
  }

  public perils(options?: { isSubPeril?: boolean }): Observable<ReferencePeril[]> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.perils);
    const params = handleApiParams(options);

    return this._apiService.get(url, { params });
  }

  public subPerils(): Observable<ReferencePeril[]> {
    return this.perils({ isSubPeril: true });
  }

  public createPeril(item: Partial<ReferencePeril>): Observable<{ uuid: UUID }> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.perils);
    return this._apiService.post(url, item).pipe(tap(() => this._trackingService.trackEvent(CATEGORIES.CREATEPERIL, { code: item.code })));
  }

  public deletePeril(perilUuid: UUID): Observable<void> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.peril(perilUuid));
    return this._apiService.delete(url).pipe(tap(() => this._trackingService.trackEvent(CATEGORIES.DELETEREGION, { perilUuid })));
  }

  public regions<T extends ReferenceRegion>(options?: { excludeBottomRegions?: boolean; isSubRegion?: boolean }): Observable<T[]> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.regions);
    let params = new HttpParams();

    const addParams = (key: string) => {
      if (key) {
        params = params.set(key, String(options[key]));
      }
    };

    if (options) {
      Object.keys(options).forEach(addParams);
    }
    return this._apiService.get(url, { params });
  }

  public subRegions(): Observable<ReferenceSubRegion[]> {
    return this.regions<ReferenceSubRegion>({ isSubRegion: true });
  }

  public createRegion(item: Partial<ReferenceRegion>): Observable<{ uuid: UUID }> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.regions);
    return this._apiService.post(url, item).pipe(
      tap(() =>
        this._trackingService.trackEvent(CATEGORIES.CREATEREGION, {
          code: item.code,
        })
      )
    );
  }

  public deleteRegion(regionUuid: UUID): Observable<void> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.region(regionUuid));
    return this._apiService.delete(url).pipe(tap(() => this._trackingService.trackEvent(CATEGORIES.DELETEREGION, { regionUuid })));
  }

  public rateViews(): Observable<ReferenceRateView[]> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.rateViews);
    return this._apiService.get(url);
  }

  public createRateView(item: Partial<ReferenceRateView>): Observable<{ uuid: UUID }> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.rateViews);
    return this._apiService.post(url, item);
  }

  public deleteRateView(rateViewUuid: UUID): Observable<void> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.rateView(rateViewUuid));
    return this._apiService.delete(url);
  }

  public resolutions(): Observable<ReferenceResolution[]> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.resolutions);
    return this._apiService.get(url);
  }

  public lobs(): Observable<ReferenceLob[]> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.lobs);
    return this._apiService.get(url);
  }

  public dataVersions(): Observable<ReferenceDataVersion[]> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.dataVersions);
    return this._apiService.get(url);
  }

  public dataVersion(uuid: UUID): Observable<ReferenceDataVersion> {
    const url = this._addBaseUrl(CONSTANTS.referenceUrl.dataVersion(uuid));
    return this._apiService.get(url);
  }

  private _addBaseUrl(path: string): string {
    return `${ENVIRONMENT.baseUrl.reference}${path}`;
  }
}

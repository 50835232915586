import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { queryParams } from '@shared/utilities/params/queryParams';
import { ENVIRONMENT } from 'environment';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';

import { UserUuidDict } from './user-uuid-dict.interface';
import { APIService } from '../api/api.service';

@Injectable()
export class UuidService {
  constructor(private _apiService: APIService) {}

  public getCompanyNames(uuids: string[]): Observable<{ [companyUuid: string]: string }> {
    const params = new HttpParams();
    const url = `${this._addBaseUrl(CONSTANTS.securityUrl.organizationNames)}${queryParams({
      uuid: uniq(uuids),
    })}`;
    return this._apiService.get(url, { params });
  }

  public getUserNames(): Observable<UserUuidDict> {
    const url: string = this._addBaseUrl(CONSTANTS.securityUrl.userNames);
    return this._apiService.get(url);
  }

  private _addBaseUrl(path: string): string {
    return `${ENVIRONMENT.baseUrl.cms}${path}`;
  }
}

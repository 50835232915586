<h1 mat-dialog-title>Delete {{ capitalize(data.type) }}</h1>

<div mat-dialog-content>
  <h5 class="delete-dialog-info" *ngIf="data.info">{{ data.info }}</h5>
  <h5 class="delete-dialog-msg">{{ data.msg }}</h5>

  <ul *ngIf="data.lines">
    <li *ngFor="let line of data.lines">{{ line }}</li>
  </ul>

  <mat-checkbox *ngIf="data.canBeHidden" [(ngModel)]="data.neverShow"> Do not show this message again </mat-checkbox>
</div>

<div mat-dialog-actions>
  <button mat-flat-button class="radius radius-plain ext-cancel" (click)="negative()" tabindex="1">Cancel</button>

  <button mat-flat-button class="radius radius-danger ext-ok" (click)="affirmative()" tabindex="2">
    {{ data.action }}
  </button>
</div>

import { ElementRef, Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { CONSTANTS } from '@shared/constants';
import { DataVersion } from '@shared/interfaces/data-version.interface';
import { Describable } from '@shared/interfaces/describable.interface';
import { LocalStore } from '@shared/storage/local-store.class';
import { ENVIRONMENT } from 'environment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from './user.interface';
import { SoftwareVersion } from './software-version.interface';
import { AccessPermitRole, AccessVectorRole } from './role.interface';
import { Organization } from './organization.interface';
import { AccessZone } from './access-zone.interface';
import { AccessVector } from './access-vector.interface';
import { AccessPermit } from './access-permit.interface';
import { APIService } from '../api/api.service';

// TODO: Update UA endpoints not following fetch spec: #3398
const _getPermissionGroupName = (value: number): string => {
  let permissionGroupName;
  switch (value) {
    case 1:
      permissionGroupName = `Viewer`;
      break;
    case 3:
      permissionGroupName = `Editor`;
      break;
    case 7:
      permissionGroupName = `Creator`;
      break;
    case 15:
      permissionGroupName = `Finalizer`;
      break;
    case 31:
      permissionGroupName = `Publisher`;
      break;
    case 127:
      permissionGroupName = `Admin`;
      break;
    default:
      permissionGroupName = `Error: can not resolve level ${value}`;
  }
  return permissionGroupName;
};

@Injectable()
export class AccountService {
  public filterEl: ElementRef;
  public reloadListData$: Subject<{ orgs?: boolean; users?: boolean; roles?: boolean }>;
  public localStore = new LocalStore<any>(window.localStorage, 'account');

  public settingsStore = new LocalStore<any>(this.localStore, 'settings');

  constructor(private _apiService: APIService) {
    this.reloadListData$ = new Subject();
  }

  public parseId(data: { paramMap?: ParamMap; id?: number }): number {
    const id = data.paramMap ? (data.paramMap.has('id') ? data.paramMap.get('id') : undefined) : data.id || undefined;
    return Number(id);
  }

  public getDataVersions(): Observable<DataVersion[]> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.miuDataVersions)}`;
    return this._apiService.get(url);
  }

  public createDataVersion(description: string): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.miuDataVersion(description))}`;
    const postData = { description };
    return this._apiService.post(url, postData, { responseType: 'text' });
  }

  public deleteDataVersion(description: string): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.miuDataVersion(description))}`;
    return this._apiService.delete(url, { responseType: 'text' });
  }

  public getSoftwareVersions(): Observable<SoftwareVersion[]> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.miuSoftwareVersions)}`;
    return this._apiService.get(url);
  }

  public createSoftwareVersion(description: string): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.miuSoftwareVersion(description))}`;
    const postData = { description };
    return this._apiService.post(url, postData, { responseType: 'text' });
  }

  public deleteSoftwareVersion(description: string): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.miuSoftwareVersion(description))}`;
    return this._apiService.delete(url, { responseType: 'text' });
  }

  public getAccessZones(): Observable<AccessZone[]> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.permissions)}`;
    return this._apiService.get(url);
  }

  public updateAccessZone(name: string, description: string): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.permission(name))}`;
    const postData = { description };
    return this._apiService.put(url, postData, { responseType: 'text' });
  }

  public createAccessZone(name: string, description: string): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.permissions)}`;
    const postData = { name, description };
    return this._apiService.post(url, postData, { responseType: 'text' });
  }

  public deleteAccessZone(name: string): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.permission(name))}`;
    return this._apiService.delete(url, { responseType: 'text' });
  }

  public createRole(name: string, description: string, accessPermits: (AccessPermit & Describable)[]): Observable<IdResponse> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.roles)}`;
    const accessVectors: AccessVector[] = accessPermits.map((ap) => ({
      permission: ap.accessZoneName,
      level: this._getPermissionGroupValue(ap.permissionGroupName),
    }));
    return this._apiService.post(url, { name, description, permissionLevels: accessVectors });
  }

  public updateRole(id: number, name: string, description: string, accessPermits: (AccessPermit & Describable)[]): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.role(id))}`;
    const accessVectors = accessPermits.map((ap) => ({
      permission: ap.accessZoneName,
      level: this._getPermissionGroupValue(ap.permissionGroupName),
    }));
    return this._apiService.put(url, { id, name, description, permissionLevels: accessVectors });
  }

  public deleteRole(id: number): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.role(id))}`;
    return this._apiService.delete(url);
  }

  public getRoles(): Observable<AccessPermitRole[]> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.roles)}`;
    return this._apiService.get(url).pipe(map((roles) => roles.map(this._convertRole)));
  }

  public getRole(id: number): Observable<AccessPermitRole> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.role(id))}`;
    return this._apiService.get(url).pipe(map(this._convertRole));
  }

  public updateRoleOrganizations(roleId: number, organizationIds: number[]): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.roleOrganization(roleId))}`;
    return this._apiService.put(url, { organizationIds });
  }

  public createOrganization(org: Organization): Observable<IdResponse> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.organizations)}`;
    return this._apiService.post(url, org);
  }

  public updateOrganization(org: Organization): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.organization(org.id))}`;
    return this._apiService.put(url, org);
  }

  public deleteOrganization(id: number): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.organization(id))}`;
    return this._apiService.delete(url);
  }

  public getOrganization(id: number): Observable<Organization> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.organization(id))}`;
    return this._apiService.get(url);
  }

  public getOrganizationUsers(id: number): Observable<User[]> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.organizationUsers(id))}`;
    return this._apiService.get(url);
  }

  public getOrganizations(): Observable<Organization[]> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.organizations)}`;
    return this._apiService.get(url);
  }

  public addUser(orgId: number, userId: number): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.organizationUser(orgId, userId))}`;
    return this._apiService.post(url, {}, { responseType: 'text' });
  }

  public removeUser(orgId: number, userId: number): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.organizationUser(orgId, userId))}`;
    return this._apiService.delete(url);
  }

  public createUser(user: { user: User; passwordBaseUrl: string }): Observable<IdResponse> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.users)}`;
    return this._apiService.post(url, user);
  }

  public updateUser(user: User): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.user(user.id))}`;
    return this._apiService.put(url, user);
  }

  public deleteUser(id): Observable<void> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.user(id))}`;
    return this._apiService.delete(url);
  }

  public getUser(id: number): Observable<User> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.user(id))}`;
    return this._apiService.get(url);
  }

  public getUsers(): Observable<User[]> {
    const url = `${this._absUrl(CONSTANTS.securityUrl.users)}`;
    return this._apiService.get(url);
  }

  private _getPermissionGroupValue(permissionGroupName: string): number {
    let value;
    switch (permissionGroupName) {
      case `Viewer`:
        value = 1;
        break;
      case `Editor`:
        value = 3;
        break;
      case `Creator`:
        value = 7;
        break;
      case `Finalizer`:
        value = 15;
        break;
      case `Publisher`:
        value = 31;
        break;
      case `Admin`:
        value = 127;
        break;
      default:
        value = 0;
    }
    return value;
  }

  private _convertRole(role: AccessVectorRole): AccessPermitRole {
    const accessPermits = role.permissionLevels.map(
      (permissionLevel: AccessVector): AccessPermit => ({
        accessZoneName: permissionLevel.permission,
        permissionGroupName: _getPermissionGroupName(permissionLevel.level),
      })
    );
    return {
      id: role.id,
      name: role.name,
      description: role.description,
      accessPermits,
    };
  }

  private _absUrl(path: string) {
    return `${ENVIRONMENT.baseUrl.cms}${path}`;
  }
}

interface IdResponse {
  id: number;
}

import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { ENVIRONMENT } from 'environment';

import { OauthToken } from './oauth-token.interface';

@Injectable()
export class AuthService {
  constructor(private _oauthService: OAuthService) {
    this._oauthService.configure(this._authConfig);
  }

  public get bearerAuthToken(): string {
    return `Bearer ${this.accessToken}`;
  }

  public get accessToken(): string {
    return this._oauthService.getAccessToken();
  }

  public get refreshToken(): string {
    return this._oauthService.getRefreshToken();
  }

  public login(email: string, password: string): Promise<OauthToken> {
    return this._oauthService.fetchTokenUsingPasswordFlow(email, password).then(
      (response: OauthToken) => response,
      (error) => error
    );
  }

  public isAuthenticated(): boolean {
    return this._oauthService.hasValidAccessToken();
  }

  public refreshAccessToken(): Promise<any> {
    return this._oauthService.refreshToken();
  }

  public logout(): void {
    this._oauthService.logOut();
  }

  private get _isRunningLocally(): boolean {
    return window.location.hostname === 'localhost';
  }

  private get _authConfig(): AuthConfig {
    const tokenEndpoint = `
      ${ENVIRONMENT.baseUrl.cms}${CONSTANTS.securityUrl.create}
    `;

    return {
      tokenEndpoint,
      clientId: this._isRunningLocally ? 'dev_insights' : 'miu_insights',
      redirectUri: ENVIRONMENT.baseUrl.root + '/',
      scope: null,
      requireHttps: false,
      oidc: false,
    };
  }
}

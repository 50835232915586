import { NgModule } from '@angular/core';

import { YearlossService } from './yearloss.service';

@NgModule({
  /* eslint-disable */
  imports: [],
  declarations: [],
  exports: [],
  providers: [YearlossService],
})
export class YearlossModule {}

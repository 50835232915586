import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CONSTANTS } from '@shared/constants';
import {
  APIService,
  AuthService,
  AuthSmlService,
  IdleService,
  NotificationService,
  QueryParamService,
  RecentActionsService,
  SessionService,
  SnackbarService,
  TrackingService,
} from '@shared/services';
import { ENVIRONMENT } from 'environment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { NavService } from './+nav/nav.service';

@Component({
  selector: 'mit-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnDestroy {
  public subnav = [];
  public navBreadcrumb = undefined;
  private _unsubscribe: Subject<any> = new Subject();

  constructor(
    private _sessionService: SessionService,
    private _authService: AuthService,
    private _trackingService: TrackingService,
    private _navService: NavService,
    private _router: Router,
    private _apiService: APIService,
    private _snackBarService: SnackbarService,
    private _notificationService: NotificationService,
    private _idleService: IdleService,
    private _recentActionsService: RecentActionsService,
    private _queryParamService: QueryParamService,
    private _authSmlService: AuthSmlService
  ) {
    this._navService.subnav$.pipe(takeUntil(this._unsubscribe)).subscribe((nav) => {
      this.subnav = nav.menu;
      this.navBreadcrumb = nav.breadcrumb;
    });

    this._apiService.responseErrorStatus$
      .pipe(
        distinctUntilChanged(),
        filter((status: number) => status === 0)
      )
      .subscribe((status: number) => this._handleHttpUnhandledError(status));

    this._router.events.pipe(takeUntil(this._unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationStart && this._isHashRoute(event)) {
        this._router.navigate([event.url.replace('/#', '').split('?')[0]]);
      } else if (event instanceof NavigationEnd && (!this._isRouteWithoutShell(event.urlAfterRedirects) || this._isHomeRoute())) {
        const authService = ENVIRONMENT.isMiuEnvironment ? this._authService : this._authSmlService;
        if (!authService.isAuthenticated() && !this.hasSession()) {
          this._sessionService.logoutAndRedirect(this._currentUrl);
        } else if (authService.isAuthenticated() && !this.hasSession()) {
          this._sessionService.acquireSession().then(() => {
            this._navService.goToUrlIfAvailable(this._currentUrl);
            this._notificationService.init();
          });
        }

        if (this.hasSession() && !this._notificationService.isRunning) {
          this._notificationService.init();
        }

        if (!this._idleService.isRunning) {
          this._idleWatch();
        }

        this._recentActionsService.init();
        this._queryParamService.setInitialQueryParams();
      }
    });

    if (this._canTrack()) {
      this._sessionService.createSession$.pipe(takeUntil(this._unsubscribe)).subscribe(() => this._trackingService.setupTracking());
    }
  }

  public hasSession(): boolean {
    return this._sessionService.hasSession();
  }

  public canShowShell(): boolean {
    return !this._isRouteWithoutShell();
  }

  public browserIsIE(): boolean {
    return window.navigator.userAgent.indexOf('Trident') > -1;
  }

  public canRenderApplication(): boolean {
    return this._isRouteWithoutShell() || (this.hasSession() && !this._isRouteWithoutShell());
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  private _isRouteWithoutShell(urlSegment: string = window.location.href): boolean {
    return this._isLoginRoute(urlSegment) || this._isMaintenanceRoute(urlSegment) || this._isHomeRoute(urlSegment);
  }

  private _isLoginRoute(urlSegment: string = window.location.href): boolean {
    return /login/.test(urlSegment);
  }

  private _isMaintenanceRoute(urlSegment: string = window.location.href): boolean {
    return /maintenance/.test(urlSegment);
  }

  private _isHomeRoute(urlSegment: string = window.location.href): boolean {
    return /home/.test(urlSegment);
  }

  private _isHashRoute(event: NavigationStart): boolean {
    return event.url && event.url.indexOf('/#') > -1;
  }

  private _canTrack(): boolean {
    return ENVIRONMENT.tracking.track;
  }

  private _idleWatch(): void {
    this._idleService.init(this._currentUrl);
  }

  private _handleHttpUnhandledError(status: number) {
    this._snackBarService.error(`${CONSTANTS.unhandledErrorMsg} - API status: ${status}`);
  }

  private get _currentUrl(): string {
    return window.location.pathname.slice(1);
  }
}

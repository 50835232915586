import { Injectable } from '@angular/core';
import { WebSocketData } from '@shared/services/web-socket/web-socket-data.interface';
import { Subject } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { SessionService } from '../session/session.service';
import { WebSocketEvent } from './web-socket-event.enum';

const reconnectDelay = 5000;

@Injectable()
export class WebSocketService {
  private _connections: Map<string, WSConnection> = new Map();

  constructor(private _authService: AuthService, private _sessionService: SessionService) {}

  public connect(url: string): Subject<WebSocketData> {
    if (this._connections.has(url)) {
      return this._connections.get(url).subject$;
    } else {
      const connection = {
        socket: new WebSocket(this._getUrl(url)),
        subject$: new Subject<WebSocketData>(),
      };
      this._connections.set(url, connection);
      connection.socket.onopen = () => this._setupOnMessage(connection);
      connection.socket.onerror = (event: Event) => this._onError(event, connection.subject$, url);
      connection.socket.onclose = (event: CloseEvent) => this._retryConnect(event, url, connection.subject$);
      return connection.subject$;
    }
  }

  private _onError(event: Event, subject: Subject<WebSocketData>, url: string): void {
    subject.next({ event: WebSocketEvent.ERROR, body: undefined });
    subject.error(event);
    const closeEvent: CloseEvent = {
      ...event,
      code: 4011,
      reason: 'Internal error',
      wasClean: true,
    };
    this._retryConnect(closeEvent, url, subject);
  }

  private _retryConnect(event: CloseEvent, url: string, subject: Subject<WebSocketData>): void {
    subject.next({ event: WebSocketEvent.RETRYING, body: undefined });
    console.error(`Websocket reconnecting due to: ${event.reason}`);
    this._connections.delete(url);

    if (this._sessionService.hasSession()) {
      setTimeout(() => this.connect(url), reconnectDelay);
    }
  }

  private _setupOnMessage(connection: WSConnection): void {
    connection.socket.onmessage = (event: MessageEvent) => {
      const data: WebSocketData = this._jsonData(event.data);

      if (!this._isKeepAliveEvent(data)) {
        connection.subject$.next(data);
      }
    };

    connection.subject$.next({ event: WebSocketEvent.CONNECTED, body: undefined });
  }

  private _jsonData(data: string): WebSocketData {
    return JSON.parse(data);
  }

  private _isKeepAliveEvent(data: WebSocketData): boolean {
    return data.event === WebSocketEvent.KEEP_ALIVE;
  }

  private _getUrl(url: string): string {
    return `${this._convertToWsUrl(url)}${this._token(url)}`;
  }

  private _convertToWsUrl(url: string): string {
    return url.replace('http', 'ws');
  }

  private _token(url: string): string {
    const hasQueryString = url.indexOf('?') > -1;
    return `${hasQueryString ? '&' : '?'}token=${this._authService.accessToken}`;
  }
}

interface WSConnection {
  socket: WebSocket;
  subject$: Subject<WebSocketData>;
}

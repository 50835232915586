import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { isUndefined } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { urlQueryParamMap } from './url-query-param-map.const';
import { QueryParam } from './query-param.enum';

const emptyFilterParam = '[{}]';

@Injectable({
  providedIn: 'root',
})
export class QueryParamService {
  constructor(private _route: ActivatedRoute, private _router: Router) {}

  public setInitialQueryParams(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        switchMap((event) => this.getQueryParams((event as NavigationStart).url)),
        switchMap((params) => this._getNavigationEnd(params)),
        take(1)
      )
      .subscribe((params) => {
        if (Object.keys(params).length > 0) {
          this.setQueryParams(params);
        }
      });
  }

  public setQueryParams(params: Params, url?: string): void {
    const queryParams = url ? this._filterParamsByMap(url, params) : params;
    this._router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

  public getQueryParams(url: string): Observable<Params> {
    return this._route.queryParams.pipe(
      take(1),
      map((params) => this._filterParamsByMap(url, params))
    );
  }

  public stringifyParam(param: any): string {
    return JSON.stringify(param);
  }

  public parseParam(param: string): any {
    return JSON.parse(param);
  }

  public hasFilterParams(filterParams: string): boolean {
    return filterParams && filterParams !== emptyFilterParam;
  }

  private _filterParamsByMap(url: string, params: Params): Params {
    return Object.keys(params)
      .filter((paramKey) => this._canAddQueryParam(url, paramKey as QueryParam))
      .reduce((newParams, paramKey) => {
        newParams[paramKey] = params[paramKey];
        return newParams;
      }, {});
  }

  private _getNavigationEnd(params: Params): Observable<Params> {
    return this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => params)
    );
  }

  private _canAddQueryParam(url: string, param: QueryParam): boolean {
    return urlQueryParamMap[Object.keys(urlQueryParamMap).find((regex) => new RegExp(regex).test(url))]?.includes(param);
  }
}

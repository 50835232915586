export const POTENTIAL_CLIENT = 'potential-client';
export const CAT_IN_A_BOX = 'catinabox';
export const CONTRACT = 'contract';
export const CONTRACT_BUILDER = 'contractbuilder';
export const CONTRACT_USER_ANALYTICS = 'ContractUserAnalytics';
export const PUBLIC_CONTRACT = 'publiccontract';
export const MIU_PROFILE = 'miuprofile';
export const CONTRACT_DOCUMENT = 'contractdocument';
export const GENERAL_DOCUMENT = 'generaldocument';
export const PRICING_DOCUMENT = 'pricingdocument';
export const MIU_RELEASE = 'miurelease';
export const EP_TO_ELT = 'eptoelt';
export const BULK_EMAIL_NOTIFICATION = 'bulkemailnotification';
export const USER_ACCOUNT = 'useraccount';

export const WIP = 'wip';
export const WIP_APIDOCS_DOCUMENTS = 'wip-apidocs-documents';
export const WIP_APIDOCS_NOTIFICATIONS = 'wip-apidocs-notifications';
export const WIP_APIDOCS_FILES = 'wip-apidocs-files';
export const WIP_EXTENSIBLE_METADATA = 'wip-ext-metadata';
export const WIP_RECENT_ACTIONS = 'wip-recent-actions';

export const WIP_PRINCIPAL_CURRENCY_SUPPORT = 'wip-principal-currency-support';
export const WIP_NEW_SHARE_FACTOR_CREATION = 'wip-new-share-factor-creation';
export const WIP_SIMULATED_MODEL_OPTIONS_SUPPORT = 'wip-simulated-model-options-support';
export const WIP_SEARCH_OLD_ANALYSIS = 'wip-search-old-analysis';
export const WIP_GENERATE_RISK_PROFILE = 'wip-generate-risk-profile';
export const WIP_PERIL_REGION_METRICS_V2 = 'wip-peril-region-metrics-v2';
export const WIP_VIEW_CONTRACT_METADATA = 'wip-view-contract-metadata';
export const WIP_EDIT_CONTRACT_METADATA = 'wip-edit-contract-metadata';
export const WIP_EDIT_CONTRACT_RISK_METADATA = 'wip-edit-contract-risk-metadata';

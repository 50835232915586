import { NgModule } from '@angular/core';
import { AuthModule } from '@shared/services/auth';
import { SecurityModule } from '@shared/services/security';

import { SessionService } from './session.service';

@NgModule({
  // eslint-disable-next-line
  imports: [SecurityModule, AuthModule],
  declarations: [],
  exports: [],
  providers: [SessionService],
})
export class SessionModule {}

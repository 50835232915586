import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LimitedCharsModule } from './input-validation/limitedChars.module';

@NgModule({
  imports: [CommonModule, LimitedCharsModule, RouterModule],
  declarations: [],
  exports: [CommonModule, LimitedCharsModule, RouterModule],
})
export class GlobalsModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    data: {
      title: 'Home',
    },
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'contracts',
    data: {
      title: 'Directory',
    },
    loadChildren: () => import('./contracts-directory/contracts-directory.module').then((m) => m.ContractsDirectoryModule),
  },
  {
    path: 'download',
    data: {
      title: 'Download',
    },
    loadChildren: () => import('./download/download.module').then((m) => m.DownloadModule),
  },
  {
    path: 'epelt',
    data: {
      title: 'EP to ELT',
      helpMenuId: 'eptoelt',
    },
    loadChildren: () => import('./epelt/epelt.module').then((m) => m.EpEltModule),
  },
  {
    path: 'ciab',
    data: {
      title: 'Cat In A Box',
      helpMenuId: 'ciab',
    },
    loadChildren: () => import('./ciab/ciab.module').then((m) => m.CiabModule),
  },
  {
    path: 'contract',
    data: {
      title: 'Contracts',
      helpMenuId: 'contract',
    },
    loadChildren: () => import('./contract/contract.module').then((m) => m.ContractModule),
  },
  {
    path: 'account',
    data: {
      title: 'User Accounts',
    },
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'docs',
    data: {
      title: 'API Explorer',
    },
    loadChildren: () => import('./docs/docs.module').then((m) => m.DocumentationModule),
  },
  {
    path: 'usage',
    data: {
      title: 'Usage Dashboard',
      admin: false,
    },
    loadChildren: () => import('./usage/usage.module').then((m) => m.UsageModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  {
    path: 'passwordreset',
    redirectTo: '/login/reset-password?prt=:prt',
    pathMatch: 'full',
  },
  // IMP: 404 component: #3194
  // {
  //   path: '**',
  // }
];

@NgModule({
  // eslint-disable-next-line
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackbarComponent } from './snackbar.component';

const error = 'error';
const success = 'success';
const warning = 'warning';
const info = 'info';
const hasAction = 'has-action';
@Injectable()
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  public success(text: string, config: MatSnackBarConfig = { duration: 3000 }, action?: string): MatSnackBarRef<SnackbarComponent> {
    return this._open([success], text, config, action);
  }

  public error(text: string, config: MatSnackBarConfig = { duration: 0 }, action?: string): MatSnackBarRef<SnackbarComponent> {
    return this._open([error], text, config, action);
  }

  public warning(text: string, config: MatSnackBarConfig = { duration: 3000 }, action?: string): MatSnackBarRef<SnackbarComponent> {
    return this._open([warning], text, config, action);
  }

  public info(text: string, config: MatSnackBarConfig = { duration: 3000 }, action?: string): MatSnackBarRef<SnackbarComponent> {
    return this._open([info], text, config, action);
  }

  private _open(panelClasses: string[], text: string, config: MatSnackBarConfig, action: string): MatSnackBarRef<SnackbarComponent> {
    return this._snackBar.openFromComponent(
      SnackbarComponent,
      Object.assign({}, config, {
        data: { text, action },
        panelClass: action ? panelClasses.concat(hasAction) : panelClasses,
      })
    );
  }
}

import { HttpParams } from '@angular/common/http';

export const handleApiParams = (options: { [index: string]: string | number | boolean }): HttpParams => {
  let params = new HttpParams();

  const addParams = (key: string) => {
    if (key) {
      params = params.set(key, String(options[key]));
    }
  };

  if (options) {
    Object.keys(options).forEach(addParams);
  }

  return params;
};

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { SatPopoverModule } from '@ncstate/sat-popover';
import * as Sentry from '@sentry/angular-ivy';
import { GlobalsModule } from '@shared/globals/globals.module';
import { APIModule } from '@shared/services/api';
import { AuthModule } from '@shared/services/auth';
import { IdleModule } from '@shared/services/idle';
import { NotificationModule } from '@shared/services/notification';
import { QueryParamModule } from '@shared/services/query-param';
import { SessionModule } from '@shared/services/session';
import { TrackingModule } from '@shared/services/tracking';
import { CookieService } from 'ngx-cookie-service';

import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavModule } from './+nav/nav.module';
import { HeaderModule } from './+header/header.module';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    GlobalsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,

    SessionModule,
    APIModule,
    AuthModule,
    NotificationModule,
    IdleModule,
    QueryParamModule,

    MatButtonModule,
    MatSidenavModule,
    MatIconModule,

    SatPopoverModule,
    LeafletModule,
    LeafletDrawModule,

    TrackingModule,
    NavModule,
    HeaderModule,

    LoginModule,

    AppRoutingModule,
  ],
  providers: [
    CookieService,
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
})
export class AppModule {
  constructor(trace: Sentry.TraceService, private _domSanitizer: DomSanitizer, private _iconRegistry: MatIconRegistry) {
    this._registerIcons();
  }

  private _registerIcons(): void {
    const icons = {
      risk_profile: 'Risk-Profile-v1.svg',
      miu_release: 'MIU-Releases-v2.svg',
      pricing_report: 'Pricing-Reports-v2.svg',
      custom_analytics: 'Custom-Analytics-v1.svg',
      risk_data: 'Risk-Data-v3.svg',
      lookup_table: 'Lookup-Table-v1.svg',
      technical_specification: 'Technical-Specifications-v2_1.svg',
      event_bulletin: 'Event-Bulletin-v2.svg',
      fact_sheet: 'Fact-Sheets-v2.svg',
      document: 'Documents-v3.svg',
      api_explorer: 'API-Explorer-v3.svg',
      calculation_templates: 'Calculation-Templates-v2.svg',
      usage: 'Analytics.svg',

      portfolio: 'Portfolio-v1.svg',
      program: 'Programs-v2.svg',

      rms_logo: 'RMS-Logo.svg',
      insights_text: 'RMS-MIU-Insights-ID.svg',
    };

    Object.keys(icons).forEach((name) => {
      this._iconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/icon/${icons[name]}`));
    });
  }
}

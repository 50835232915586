import { PermissionService } from '@shared/services';
import { WIP_NEW_SHARE_FACTOR_CREATION } from '@shared/services/permission/access-zones.constant';

import { PortfolioTableItemProp, ProgramTableItemProp, RiskDataTableItemProp } from './+contract-page/contractPage.const';
import { ContractNavItemFilter } from './contract-nav-item-filter.enum';
import { ContractNavItem } from './contract-nav-item.interface';
import { ContractType } from './contract-type.enum';

export const contractMenu = (permissionService: PermissionService): ContractNavItem[] => [
  {
    label: 'Portfolios',
    contractType: ContractType.PORTFOLIOS,
    state: `/contract/${ContractType.PORTFOLIOS}`,
    svgIcon: 'portfolio',
    dataTableColumns: [
      { label: 'Name', prop: PortfolioTableItemProp.NAME, canSort: true },
      { label: 'Modified By', prop: PortfolioTableItemProp.MODIFIEDBYNAME, canSort: true },
      {
        label: 'Modified At',
        prop: PortfolioTableItemProp.DISPLAYMODIFIEDAT,
        canSort: true,
        sortType: 'date',
      },
      { label: 'Data Versions', prop: PortfolioTableItemProp.DISPLAYDATAVERSIONS, canSort: true },
    ],
    dataTableOptions: {
      sortByColumn: 'displayModifiedAt',
      pagination: true,
      showActions: true,
    },
    toolbar: {
      canFilter: true,
      buttons: [{ label: 'Portfolio', matIcon: 'add', state: 'new', canRead: true }],
      filters: [ContractNavItemFilter.DATAVERSION, ContractNavItemFilter.MODIFIEDBY],
    },
  },
  {
    label: 'Programs',
    contractType: ContractType.PROGRAMS,
    state: `/contract/${ContractType.PROGRAMS}`,
    svgIcon: 'program',
    dataTableColumns: [
      { label: 'Name', prop: ProgramTableItemProp.NAME, canSort: true },
      { label: 'Source', prop: ProgramTableItemProp.DISPLAYSOURCE, canSort: true },
      { label: 'Year', prop: ProgramTableItemProp.DISPLAYYEAR, canSort: true },
      { label: 'Modified By', prop: ProgramTableItemProp.MODIFIEDBYNAME, canSort: true },
      {
        label: 'Modified At',
        prop: ProgramTableItemProp.DISPLAYMODIFIEDAT,
        canSort: true,
        sortType: 'date',
      },
      { label: 'Status', prop: ProgramTableItemProp.STATUS, canSort: true },
      { label: 'Public', prop: ProgramTableItemProp.PUBLICSTRING, canSort: true },
      {
        label: 'Data Versions',
        prop: ProgramTableItemProp.DISPLAYDATAVERSIONS,
        canSort: true,
      },
    ],
    dataTableOptions: {
      sortByColumn: 'displayModifiedAt',
      pagination: true,
      showActions: true,
    },
    toolbar: {
      canFilter: true,
      buttons: [{ label: 'Program', matIcon: 'add', state: 'new', canRead: true }],
      filters: [
        ContractNavItemFilter.DATAVERSION,
        ContractNavItemFilter.MODIFIEDBY,
        ContractNavItemFilter.PUBLIC,
        ContractNavItemFilter.STATUS,
      ],
    },
  },
  {
    label: 'Risk Data',
    contractType: ContractType.RISKDATA,
    state: `/contract/${ContractType.RISKDATA}`,
    svgIcon: 'risk_data',
    dataTableColumns: [
      { label: 'Name', prop: RiskDataTableItemProp.DESCRIPTION, canSort: true },
      { label: 'Region', prop: RiskDataTableItemProp.REGIONCODE, canSort: true },
      { label: 'Peril', prop: RiskDataTableItemProp.PERILCODE, canSort: true },
      { label: 'Created By', prop: RiskDataTableItemProp.DISPLAYCREATEDBY, canSort: true },
      {
        label: 'Created At',
        prop: RiskDataTableItemProp.DISPLAYCREATEDAT,
        canSort: true,
        sortType: 'date',
      },
      { label: 'Currency', prop: RiskDataTableItemProp.CURRENCY, canSort: true },
      {
        label: 'Data Version',
        prop: RiskDataTableItemProp.DISPLAYDATAVERSIONID,
        canSort: true,
      },
      { label: 'Public', prop: RiskDataTableItemProp.PUBLICSTRING, canSort: true },
      { label: 'Type', prop: RiskDataTableItemProp.DISPLAYEXPOSURETYPE, canSort: true },
    ],
    dataTableOptions: {
      sortByColumn: 'displayCreatedAt',
      pagination: true,
      showActions: true,
    },
    toolbar: {
      canFilter: true,
      buttons: [
        {
          label: 'Share Factors',
          matIcon: 'add',
          state: permissionService.getGrants(WIP_NEW_SHARE_FACTOR_CREATION).read ? 'shareFactors/create' : 'shareFactors/new',
          canRead: true,
        },
        { label: 'PLT', matIcon: 'file_upload', state: 'PLT/import', canRead: true },
        { label: 'ELT', matIcon: 'file_upload', state: 'ELT/import', canRead: true },
      ],
      filters: [
        ContractNavItemFilter.DATAVERSION,
        ContractNavItemFilter.CREATEDBY,
        ContractNavItemFilter.PUBLIC,
        ContractNavItemFilter.EXPOSURETYPE,
        ContractNavItemFilter.REGION,
        ContractNavItemFilter.PERIL,
      ],
    },
  },
  {
    label: 'Settings',
    contractType: 'settings' as ContractType,
    state: '/contract/settings',
    icon: 'mdi-settings',
    toolbar: { canFilter: false, buttons: [] },
  },
];

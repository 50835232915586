import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@shared/constants';
import { CodeDescription } from '@shared/interfaces';
import { UUID } from '@shared/interfaces/uuid.type';
import { queryParams } from '@shared/utilities/params/queryParams';
import { RT, SC, ST } from 'app/contract/contract-constants.interface';
import { YltRiskProfile } from 'app/contract/ylt-risk-profile.interface';
import { ENVIRONMENT } from 'environment';
import { uniq, uniqBy } from 'lodash';
import { Observable } from 'rxjs';

import { Yearloss } from './yearloss.interface';
import { YearlossType } from './yearloss-type.enum';
import { YearlossSet } from './yearloss-set.interface';
import { PerilRegionMap } from './peril-region-map.interface';
import { CreateOrUpdateYearlossSet } from './create-or-update-yearloss-set.interface';
import { APIService } from '../api/api.service';

const typeCodeStr = 'typeCode';

@Injectable()
export class YearlossService {
  constructor(private _apiService: APIService) {}

  public get yearlossTypes(): CodeDescription[] {
    return Object.keys(YearlossType).map((type) => ({
      code: type,
      description: YearlossType[type],
    }));
  }

  public getYearlossTypeByCode(type: YearlossType): string {
    return YearlossType[type];
  }

  public getAllYearlosses(): Observable<Yearloss[]> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlosses);
    return this._apiService.get(url);
  }

  public getMultipleYearlosses(yltUuidList: UUID[]): Observable<Yearloss[]> {
    const uuids = yltUuidList.join('&uuid=');
    const url = `${this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlosses)}?uuid=${uuids}`;
    return this._apiService.get(url);
  }

  public getYearloss(uuid: UUID): Observable<Yearloss> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearloss(uuid));
    return this._apiService.get(url);
  }

  public getAllYearlossSets(): Observable<YearlossSet[]> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlossSets);
    return this._apiService.get(url);
  }

  public getAllYearlossSetsByDataVersion(dataVersionUuid: UUID): Observable<YearlossSet[]> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlossSets);
    const params = new HttpParams().set('dataVersionUuid', dataVersionUuid);
    return this._apiService.get(url, { params });
  }

  /* IMP: refactor contract-analysis service and this one's usage of yearlossLibraryUrl
               into its own service `yltlibrary.service` #5190
  */
  public getAllYltsAcrossDataVersions(uuids?: UUID[]): Observable<(YltRiskProfile<RT> | YltRiskProfile<SC> | YltRiskProfile<ST>)[]> {
    const url = `${this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlosses)}${queryParams({
      uuid: uniq(uuids),
    })}`;
    return this._apiService.get(url);
  }

  public getYearlossSet(uuid: UUID): Observable<YearlossSet> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlossSet(uuid));
    return this._apiService.get(url);
  }

  public getPerilRegions(): Observable<PerilRegionMap[]> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.perilRegions);
    return this._apiService.get(url);
  }

  public getRiskProfiles<T>(
    dataVersionUuid: UUID,
    typeCode: ST | SC | RT,
    uuids?: string[],
    isDefault?: boolean,
    primaryRegionsOnly?: boolean
  ): Observable<YltRiskProfile<T>[]> {
    const url = this._addBaseUrl(
      `${CONSTANTS.yearlossLibraryUrl.riskProfiles(dataVersionUuid)}${queryParams({
        uuid: uniq(uuids),
      })}`
    );
    let params: HttpParams = new HttpParams().set(typeCodeStr, typeCode);

    if (isDefault) {
      params = params.set('isDefault', String(isDefault));
    }

    if (primaryRegionsOnly) {
      params = params.set('primaryRegionsOnly', String(primaryRegionsOnly));
    }

    return this._apiService.get(url, { params });
  }

  public createYearlossSet(yearlossSet: CreateOrUpdateYearlossSet): Observable<{ uuid: UUID }> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlossSets);
    return this._apiService.post(url, this._uniqYlts(yearlossSet));
  }

  public updateYearlossSet(uuid: UUID, yearlossSet: CreateOrUpdateYearlossSet): Observable<{ uuid: UUID }> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlossSet(uuid));
    return this._apiService.put(url, this._uniqYlts(yearlossSet));
  }

  public deleteYearlossSet(uuid: UUID): Observable<void> {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlossSet(uuid));
    return this._apiService.delete(url);
  }

  public getYearlossHeaders() {
    const url = this._addBaseUrl(CONSTANTS.yearlossLibraryUrl.yearlossStagingHeaders);
    return this._apiService.get(url);
  }

  private _addBaseUrl(path: string): string {
    return `${ENVIRONMENT.baseUrl.ylt}${path}`;
  }

  private _uniqYlts(yearlossSet: CreateOrUpdateYearlossSet): CreateOrUpdateYearlossSet {
    return {
      ...yearlossSet,
      ...{
        stochasticYltUuids: uniq(yearlossSet.stochasticYltUuids),
        scenarioYlts: uniqBy(yearlossSet.scenarioYlts, 'yltUuid'),
        realtimeYlts: uniqBy(yearlossSet.realtimeYlts, 'yltUuid'),
      },
    };
  }
}

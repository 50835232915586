import { Component, Input } from '@angular/core';
import { TrackingService } from '@shared/services';
import { CATEGORIES } from '@shared/services/tracking/tracking.const';

import { NavItem } from '../nav-item.interface';

@Component({
  selector: 'mit-nav-list-item',
  templateUrl: './nav-list-item.component.html',
  styleUrls: ['./nav-list-item.component.scss'],
})
export class NavListItemComponent {
  @Input()
  set menuItem(menuItem: NavItem) {
    this.state.menuItem = menuItem;
  }

  @Input()
  set tooltipDisabled(tooltipDisabled: boolean) {
    this.state.tooltipDisabled = tooltipDisabled;
  }

  public state: NavListState = {
    menuItem: undefined,
    tooltipDisabled: false,
  };

  constructor(private _trackingService: TrackingService) {}

  public track(name: string): void {
    this._trackingService.trackEvent(CATEGORIES.NAVMENU, { name });
  }
}

interface NavListState {
  menuItem: NavItem;
  tooltipDisabled: boolean;
}

import { NgModule } from '@angular/core';

import { AuthSmlService } from './auth-sml.service';

@NgModule({
  // eslint-disable-next-line
  imports: [],
  declarations: [],
  exports: [],
  providers: [AuthSmlService],
})
export class AuthSmlModule {}

<nav role="navigation">
  <h1 class="app-name">MIU INSIGHTS</h1>
  <h4 class="version-name">Version: {{ state.version }}</h4>

  <ul class="nav-list">
    <hr />

    <div class="main-menu">
      <mit-nav-list-item
        *ngFor="let item of state.menus.yourMenus"
        (click)="onClickListItem()"
        [menuItem]="item"
        [tooltipDisabled]="true"
      ></mit-nav-list-item>
    </div>

    <div class="api-explorer-wrapper" *ngIf="state.menus.otherMenus">
      <hr />

      <div class="api-explorer">
        <mit-nav-list-item
          *ngFor="let item of state.menus.otherMenus"
          (click)="onClickListItem()"
          [menuItem]="item"
          [tooltipDisabled]="true"
        ></mit-nav-list-item>
      </div>
    </div>
  </ul>

  <hr />

  <div class="user-actions">
    <a class="ext-change-password" (click)="changePassword()">
      <mat-icon class="nav-icon">code</mat-icon>

      <div class="nav-label">Change Password</div>
    </a>

    <a class="ext-logout" (click)="logout()">
      <mat-icon class="nav-icon" fontSet="mdi" fontIcon="mdi-logout"></mat-icon>

      <div class="nav-label">Logout</div>
    </a>
  </div>

  <div class="user-info-wrapper">
    <hr />

    <div class="user-info">
      <div class="left">
        <div class="nav-label name">{{ state.fullName }}</div>

        <div class="nav-label">{{ state.company }}</div>
      </div>

      <div class="right">
        <mat-icon class="nav-icon">account_circle</mat-icon>
      </div>
    </div>
  </div>
</nav>

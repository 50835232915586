import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginPageComponent } from './login-page.component';
import { LoginComponent } from './login.component';

const routes: Routes = [
  {
    path: 'login',
    data: {
      title: 'User Management',
    },
    component: LoginComponent,
    children: [
      {
        path: '',
        component: LoginPageComponent,
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./forgot-password/login-forgot-password.module').then((m) => m.LoginForgotPasswordModule),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./change-password/login-change-password.module').then((m) => m.LoginChangePasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./reset-password/login-reset-password.module').then((m) => m.LoginResetPasswordModule),
      },
    ],
  },
];

@NgModule({
  // eslint-disable-next-line
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
